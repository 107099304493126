.page-container {
  margin-top: 95px;
  padding-bottom: 32px;
}

.content-wrapper {
  max-width: 1280px;
}

@media only screen and (min-width: $desktop) {
  .page-container {
    margin-top: 105px;
  }

  .container {
    max-width: 80rem;
  }
}


.portal-section {
  &.container {
    padding-bottom: 50px;
    padding-top: 40px;
  }

  &.carousels {
    display: flex;
    flex-direction: column;
    gap: 46px;
    overflow-x: hidden;
    padding-left: 28px;
    padding-right: 28px;
  }

  @media only screen and (min-width: $mobile) and (max-width: $tablet) {
    &.carousels {
      padding-left: 40px;
      padding-right: 45px;
    }
  }

  @media only screen and (min-width: $tablet) {
    &.container {
      padding-top: 95px;
      padding-bottom: 24px;
    }

    &.carousels {
      gap: 80px;
    }
  }

}

.accordion {
    margin-top: 30px;
    .accordion-item {
        border-top: 1px solid $stone-600;

        &:last-of-type {
            border-bottom: 1px solid $stone-600;
        }
    }

    button {
        display: flex;
        width: 100%;
        padding: 30px 0;
        gap: 20px;
        font-weight: 700;
        text-align: left;
    }

    .icon {
        flex: 0 0 20px;
        margin-left: auto;
        
        svg path {
            fill: $voyage-500;
        }
    }

    .accordion-content {
        padding-bottom: 30px;
    }
}
.collapsible-mobile-toggle {
  display: flex;
  justify-content: space-between;
  background: $stone;
  padding: 14px 20px 14px 20px;
  position: relative;
  align-items: center;

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 135.5%;
    text-transform: uppercase;
  }

  .icon {
    position: relative;
  }
}

.collapsible {
  .collapsible-item-title {
    color: $midnight;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  // variants
  &.mobile-menu {
    background: $stone-300;

    .collapsible-item {
      border-bottom: 0 solid transparent;

      &.active {
        border-bottom: 1px solid $stone;

        .collapsible-item-title {
          background: $stone;
        }
      }

      .collapsible-item-title {
        padding: 16px 28px;
        border-bottom: 1px solid $stone;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.35;
        transition: 
          border 200ms ease-out, 
          padding 100ms ease-out,
          background 300ms ease-out;

        a {
          font-weight: bold;
          font-size: 14px;
          line-height: 135.5%;
          text-transform: uppercase;
          width: 100%;
          text-align: left;
          z-index: 1;
          position: relative;
        }
      }

      .collapsible-item-content {
        background: $white;
        padding: 12px 0;

        .content {
          li {
            list-style: none;
            padding: 12px 22px;
          }
        }
      }
    }

    .icon-plus-minus {
      position: absolute;
      right: 28px;
    }
  }

  &.side-menu {
    max-width: 100%;

    .collapsible-item {
      border-bottom: 1px solid $stone;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: 16px 0;

      &.active {
        .collapsible-item-title {
          background: $stone-400;

          button {
            padding: 4px 0 4px 16px;

            // active vertical blue bar highlight for mobile
            &::before {
              content: '';
              display: block;
              position: absolute;
              top: calc(50% - 9px);
              left: -2px;
              width: 4px;
              height: 18px;
              background: $highlight;
            }
          }
        }
      }

      &:first-of-type {
        padding-top: 0px;
      }
    }

    .collapsible-item-title {
      .icon {
        z-index: 1;
      }
    }

    .collapsible-item-title {
      position: relative;
      transition: border 200ms ease-out, padding 100ms ease-out, background 300ms ease-out;

      &:first-of-type {
        padding-top: 0;
      }
      

      @media (min-width: 1024px) {

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: calc(50% - 9px);
          left: -2px;
          width: 6px;
          height: calc(100% - 4px);
          top: 2px;
          background: transparent;
        }
      }

      button {
        padding: 4px 0;
        width: 100%;

        a {
          width: 100%;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
          line-height: 1.35;
          text-align: left;
        }
      }
    }

    .collapsible-item-content {
      .content {
        ul {
          padding-left: 0;
          display: flex;
          row-gap: 6px;
          flex-direction: column;
          margin-left: 12px;

          li {
            font-size: 14px;
            list-style: none;
          }
        }
      }
    }

    .content {
      .content-link {
        a {
          font-size: 14px;
          line-height: 1.5;
          padding: 4px 8px;
          background: transparent;
          transition: background 200ms ease-out, padding 200ms ease-out;
        }

        &.active {
          a {
            background: $stone-400;
          }
        }
      }
    }

  }  
}

@media (min-width: $desktop) {
  .collapsible {
    &.menu-side {
      max-width: 252px;
      padding-left: 0;
    }
  }
}


.badge {
	text-transform: uppercase;
	background: $stone-600;
	border-radius: 2px;
	cursor: pointer;
	padding: 1px;

	&.regulator {
		text-align: center;
		min-width: 150px;
	}

	&.is-active{
		background: linear-gradient(to right, $voyage-300, $midnight);

		.layout {
			background-color: $stone-300
		}
	}

	&.selected-topics {
		.layout{
			padding: 8px 8px 8px 16px;
			display: flex;
			justify-content: space-between;
			gap: 16px;
		}
	}
	
	.layout {
		background-color: $white;
		border-radius: 1px;
		padding: 8px;
		transition: background 300ms ease-out;

		&:hover	{
			background-color: $stone-300
		}
	}

	.layout,
	.layout span {
		font-size: 12px;
		font-weight: 600;
		line-height: 18px;
	}

	.icon {
		height: 18px;
		
		svg {
			path {
				fill: $breeze;
			}
		}
	}
}
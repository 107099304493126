#industry-advocate {
    .content-wrapper {
        padding: 0 60px;
    }

    .content {
        display: flex;
        align-items: center;
    }

    .heading {
        flex: 0 0 50%;
        display: flex;
        padding: 127px 0;

        p {
            margin: 10px 0 20px;
        }
    }

    .parallax-image {
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            max-width: 588px;
            width: 36vw;
            margin-top: -43%;
            transform: translateY(85px);
        }

        .bg-img {
            object-fit: cover;
        }

        .image {
            border-radius: 294px;
            overflow: hidden;
            max-width: 588px;
            width: 36vw;
            max-height: 588px;
            height: 36vw;
            position: relative;
            z-index: 1;
            transform: translateY(-60px);
            transition: 0.15s;
        }

        .transparent-logo {
            position: absolute;
            width: 50%;
            top: 0;
            mix-blend-mode: lighten;
        }
    }

    @media screen and (max-width:767px) {
        .content-wrapper {
            padding: 0;
        }

        .content {
            flex-direction: column;
        }

        .heading {
            padding: 72px 20px 72px 35px;
            position: relative;
        }

        .parallax-image {
            flex: auto;

            .image {
                transform: translateY(-38px);
                width: 80vw;
                height: 80vw;
            }

            .logo {
                width: 80vw;
            }
        }
    }
}
.places-autocomplete-input-wrapper {
  position: relative;

  .btn-add-in-manually {
    position: absolute;
    top: 44px;
    right: 94px;
    line-height: 1;
    height: auto;
    font-size: 12px;
    color: $stone-700;
    font-weight: 300;
    text-transform: uppercase;
  }
}

.address-suggestions {
  background: $white;
  border: 1px solid transparent;
  margin-top: -17px;
  margin-bottom: 16px;
  padding: 0 16px;
  height: 0;
  max-height: 133px;
  overflow-y: scroll;
  transition: max-height 300ms ease-out, padding 200ms ease-out, border 200ms ease-out;

  &.active {
    height: 100%;
    padding: 16px;
    border: 1px solid $stone;

    .heading {
      opacity: 1;
      visibility: visible;
    }
  }

  .heading {
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    text-transform: uppercase;
    color: $stone-700;
    margin-bottom: 4px;
    padding-left: 8px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-out, visibility 200ms ease-out;
  }

  .suggestion-item {
    background: $white;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    color: $midnight;
    margin-bottom: 8px;
    padding: 4px 8px;
    cursor: 'pointer';
    transition: background 300ms ease-out;

    &:hover {
      background: $stone-600;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tag-group {
  column-gap: 16px;
  display: flex;
  flex-flow: row wrap;
  row-gap: 8px;
}

.tag {
  background-color: $stone-300;
  border-radius: 2px;
  color: $midnight-300;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding: 4px 12px;
  text-transform: uppercase;
  width: fit-content;
  box-sizing: border-box;

  &.article {
    background-color: $white;
    color: $midnight-300;
    border: 1px solid $midnight-300;
    cursor: pointer;
  }
  &.article:hover {
    background-color: $stone-500;
    border-color: $midnight-500;
  }

  &.error {
    background-color: $error-red;
    color: $white;
  }

  &.success {
    background-color: $voyage;
    color: $white;
  }
  
  &.title.voyage {
    background-color: $voyage;
    color: $white;
  }
  &.title.grey {
    background-color: $stone-400;
    color: $midnight-300;
  }

  &.apply-now {
    background-color: $highlight;
    color: $midnight-500;
  }

  &.training-requested {
    background-color: $info-yellow;
  }

  &.training-in-cart {
    background-color: $attention-orange;
  }

  &.training-ready-to-download {
    background-color: $success-green;
  }

  &.article-feedback-required {
    background-color: $feedback-green;
    color: $white;
  }

  &.article-feedback-closed {
    background-color: $stone-700;
    color: $white;
  }

  &.article-for-the-attention-of,
  &.event-who-should-attend {
    background-color: $attention-orange;
  }
}

#media-index-page {
  .media-content-wrapper {
    padding: 40px 20px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    .media-content {
      width: 68.3%;
    }

    .label,
    label {
      font-size: 14px;
    }
  }

  .years {
    margin-bottom: 32px;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;

      li {
        margin: 0 12px 6px 0;
      }

      button {
        border: 2px solid $stone;
        height: 44px;
        border-radius: 24px;
        font-size: 14px;
        font-weight: 600;
        color: $voyage;
        padding: 0 12px;
        line-height: 38px;
        justify-content: center;
        text-decoration: none;
        width: 97px;

        &:hover {
          background: $stone-400;
        }
      }
    }

    .active-year {
      background: $stone;
      pointer-events: none;
    }
  }

  .search {
    padding-bottom: 60px;
    border-bottom: 1px solid $stone-600;
  }

  .search-field {
    display: flex;
    margin-top: 15px;
    gap: 15px;

    input {
      flex: 1;
      font-family: 'soleil', sans-serif;
      font-size: 14px;
      color: $midnight;
    }

    button {
      height: 43px;
      width: 43px;
      background: $breeze-700;
      border-radius: 43px;
      align-items: center;
      display: flex;
    }
  }

  .featured-release {
    margin-bottom: 60px;

    .heading {
      display: flex;
      gap: 15px;
      align-items: center;
      padding: 60px 0 30px;

      img {
        width: 42px;
      }
    }

    .total-displayed {
      margin-left: auto;
      color: $midnight-300;
      font-weight: 600;
    }
  }

  .no-results {
    margin-top: 70px;

    h3 {
      margin-bottom: 20px;
      color: $voyage;
    }
  }

  @media screen and (max-width: 767px) {
    .breadcrumbs {
      display: none;
    }

    .media-content-wrapper {
      padding: 40px 20px;
      flex-direction: column;
    }

    .content-wrapper {
      .media-content {
        width: 100%;
        order: 0;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    .media-content-wrapper {
      flex-direction: row;
    }
  }

  @media screen and (min-width: $desktop) {
    .media-content-wrapper {
      padding: 40px 60px;
    }
  }
}

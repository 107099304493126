.article-sidebar {
  grid-area: articleSidebar;
  width: 100%;
  max-width: 515px;
  margin-top: 8px;

  a {
    column-gap: 4px;
  }

  p {
    font-size: 14px;
    color: $midnight-300;
  }

  h4 {
    margin-bottom: 20px;
  }

  .section {
    padding: 40px 0;
    border-top: 3px solid $breeze;
  }

  .download {
    .title {
      font-weight: 600;
      color: $midnight-500;
      line-height: 18px;
    }

    a {
      column-gap: 9px;
    }

    .icon {
      width: 43px;
      height: 43px;
      background: $breeze-700;
      border-radius: 43px;
      vertical-align: text-bottom;
    }

    .download-item {
      & + .download-item {
        margin-top: 30px;
      }
    }
  }

  .policy-contact {
    .name {
      color: $voyage;
      font-weight: 700;
    }

    .role {
      color: $voyage;
    }

    .phone,
    .mobile {
      font-size: 14px;
      color: $midnight-300;
    }

    .email {
      margin-top: 20px;

      a {
        font-weight: 600;
        font-size: 14px;

        &:hover {
          .icon {
            transform: translateX(9px);
            transition: 300ms;
          }
        }
      }
    }

    .policy-item {
      & + .policy-item {
        margin-top: 30px;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    width: 220px;
  }
}

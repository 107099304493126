@import '../../utilities/variables.scss';

.DayPickerInput {
  position: relative;
	width: 100%;

  &.active {
    .icon {
      &::before {
        transform: rotate(-135deg);
      }

      &::after {
        transform: rotate(135deg);
      }
    }
  }

  .DayPicker {
    position: absolute;
    border-radius: 2px;
    border: 1px solid $border-dark;
    background-color: $white;
		width: fit-content;
    margin-top: 8px;
    z-index: 1;
		
		.DayPicker-Weekday {
			color: $stone-800;
		}

    .DayPicker-Day {
			border-radius: 2px;
			color: $midnight-200;
			line-height: 1.1rem;
    }

		.DayPicker-Day--today {
			color: $breeze-500;
			font-weight: 700;
		}

		.DayPicker-Day--selected {
			&:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
				background-color: $voyage-500;
				color: $white;
			}
    }

		:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: $breeze-500;
      color: $white;
		}

  }

	.DayPicker-Caption {
		text-transform: uppercase;
		font-size: 14px;
	}
	
  .text-input {
    &:hover,
    &:focus {
      + .icon {
        background: transparent;
      }
    }

    &:focus {
      border-color: $voyage;
    }
  }

  .icon {
    position: absolute;
    top: 16px;
    right: 0;
    color: $stone-600;
    padding: 10px 16px;
    cursor: pointer;
    pointer-events: none;
    transition: transform 200ms ease-out, border 200ms ease-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 4px);
      width: 2px;
      height: 8px;
      background: $breeze;
      transition: transform 200ms ease-out, border 200ms ease-out;
    }

    &::before {
      right: 24px;
      transform: rotate(-45deg);
    }

    &::after {
      right: 20px;
      transform: rotate(45deg);
    }
  }
}
// Cross browser flex gap emulation
@mixin emulated-gap($gap-size) {
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * #{$gap-size}) 0 0 calc(-1 * #{$gap-size});
    width: calc(100% + #{$gap-size});

    > * {
        margin: $gap-size 0 0 $gap-size;
    }
}

.breadcrumbs-base {
    margin-bottom: 7px;

    p {
        text-transform: uppercase;
        font-weight: 600;
        color: $midnight-200;
        font-size: 14px;
        line-height: 150%;
    }
}
.article-banner {
  background: $blue-background;
  padding-top: 70px;
  position: relative;

  &.has-feature-image {
    margin-bottom: 110px;
  }

  .content-wrapper {
    padding: 45px 20px;

    &.has-feature-image {
      padding-bottom: 151px;
    }
  }

  .article-type {
    text-transform: uppercase;
    font-size: 14px;
    color: $midnight-300;

    .icon {
      margin-right: 15px;
      width: 10px;
    }
  }

  h1,
  .h1 {
    margin: 8px 0 28px 0;
  }

  .written {
    text-transform: uppercase;
  }

  .author {
    color: $voyage-700;
    font-weight: 800;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .date {
    color: $midnight-300;
    font-size: 14px;
  }

  .featured-image {
    max-width: 400px;
    max-height: 266px;
    overflow: hidden;
    position: absolute;
    bottom: -110px;
    margin-right: 60px;
    width: 100%;
    left: 20px;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  .mini-logo-common {
    position: absolute;
    bottom: -30px;
  }

  @media screen and (max-width: $tablet) {
    .mini-logo-common {
      display: none;
    }
  }

  @media screen and (min-width: $tablet) {
    h1,
    .h1 {
      margin: 8px 0 40px 0;
    }
  }

  @media screen and (min-width: $desktop) {
    padding-top: 124px;

    &.has-feature-image {
      margin-bottom: 200px;
    }

    .content-wrapper {
      padding: 45px 60px 70px 60px;
    }

    .featured-image {
      bottom: -175px;
      left: 33.33%;
      max-height: 422px;
      max-width: 618px;
    }
  }
}

.quick-links {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(1, 1fr);
  padding-top: 40px;

  .btn-quick-link {
    background: linear-gradient(to right, $voyage-300, $blue-background);
    border-radius: 2px;
    box-shadow: -8px 8px 0px $blue-background;

    .layout {
      align-items: center;
      background: $white;
      border-radius: 1px;
      display: flex;
      height: 96px;
      justify-content: space-between;
      margin: 1px;
      padding-top: 23px;
      padding-bottom: 23px;
      padding-left: 18px;
      padding-right: 14px;
      column-gap: 8px;
      width: 100%;
    }

    .text {
      flex: 1 1 80%;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media (min-width: 426px) {
  .quick-links {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .quick-links {
    padding-top: 0px;

    .btn-quick-link {
      .text {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .quick-links {
    .btn-quick-link {
      .layout {
        padding-left: 33px;
        padding-right: 31px;
      }
    }
  }
}

// Portal
@use "sass:math";

$slide-gap: 16px;

.carousel {
  padding: unset;
  row-gap: 16px;
  flex-direction: column;
  
  .card,
  .layout {
    height: 100%;
  }
}

.slide-container {
  transition: 0.8s ease-in;
  z-index: 0;
  column-gap: $slide-gap;

  .hide-slide {
    opacity: 0;
    pointer-events: none;
  }

  .slide {
    transition: 0.6s ease-in-out;
  }
}

.controls-container {

  .controls {
    flex-direction: row;
  
    // Buttons
    .btn {
      height: 50px;
      width: 50px;
      }
  
    .btn-active {
      border: 1px solid $breeze;
      border-radius: 50%;

      svg path {
        fill: $midnight-500;
      }

      &:hover {
        border: none;
        background: $stone-300;
      }
    }
  
    .btn-disabled {
      pointer-events: none;
      border: 0;
      border-radius: 50px;
    }
  }
}



@media (min-width: 768px) {
  $slide-gap: 30px;

  .carousel-header {
    flex-direction: row;
    align-items: center;

    .heading {
      margin-bottom: auto;
    }
  }
  
  .slide-container {
    transition: 0.8s ease-in;
    z-index: 0;
    column-gap: $slide-gap;
  }

  .slide {
    $half-gap: math.div($slide-gap, 2);
    width: calc(50% - #{$half-gap});
  }
}

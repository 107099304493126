.password-strength {
  .password-strength-label,
  .password-strength-result {
    text-transform: uppercase;
    color: $midnight-200;
    font-size: 12px;
  }

  .password-strength-result {
    width: 74px;
    text-align: center;
    background: $stone;
    transition: background 300ms ease-out, color 300ms ease-out;
    border-radius: 2px;
    margin-left: 10px;
    color: $stone-700;

    &.weak,
    &.average,
    &.strong {
      color: $white;
    }

    &.weak {
      background: $error-red;
    }

    &.average {
      background: $attention-orange;
    }

    &.strong {
      background: $feedback-green;
    }
  }
}

@import './article-accordion.scss';
@import './article-card.scss';
@import './blockquote.scss';
@import './buttons.scss';
@import './carousel.scss';
@import './collapsible.scss';
@import './listing-counter.scss';
@import './forms/index.scss';
@import './header.scss';
@import './footer.scss';
@import './icons.scss';
@import './menu-dropdown.scss';
@import './mini-logo.scss';
@import './pill.scss';
@import './subpage-header.scss';
@import './subscribe-form.scss';
@import './static-table.scss';
@import './hero-section.scss';
@import './announcement-bar.scss';
// Portal
@import './portal/index.scss';
@import './modals/index.scss';
@import './portal-badge.scss';
@import './portal-menu.scss';

p,
div,
a,
li,
span {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

i {
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  line-height: 36px;
}

//Links
a {
  &:not(.btn) {
    transition: 0.3s;

    &:hover {
      color: $voyage-700;
      transition: 0.3s;
    }
  }

  &.pill {
    text-decoration: none;
  }
}

//Headings
h1,
h2,
h3,
h4,
h5,
.h1,
.h1 p,
.h2,
.h2 p,
.h3,
.h3 p,
.h4,
.h4 p,
.h5,
.h5 p {
  font-weight: 700;
  font-style: normal;

  u {
    text-decoration: underline;
    position: relative;
    display: inline-block;
    text-decoration-color: $highlight;
    text-decoration-thickness: 6px;
  }
}

h1,
.h1,
.h1 p {
  font-size: 80px;
  line-height: 88px;
}

h2,
.h2,
.h2 p {
  font-size: 45px;
  line-height: 49.5px;
}

h3,
.h3,
.h3 p {
  font-size: 25px;
  line-height: 33.75px;
}

h4,
.h4,
.h4 p {
  font-size: 20px;
  line-height: 27.1px;
}

h5,
.h5,
.h5 p {
  font-size: 16px;
  line-height: 21.68px;
}

//inputs
input {
  height: 43px;
  background: $white;
  border: 1px solid $stone-600;
  border-radius: 60px;
  padding: 0 12px;
  max-width: 357px;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.select {
  .css-g1d714-ValueContainer {
    height: 43px;
  }

  div {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 400;
    color: $midnight;
    border: 0;
  }

  .css-26l3qy-menu {
    border-radius: 0;
    div {
      border-radius: 0;
    }
  }
}

select {
  height: 43px;
  background: $white;
  border: 1px solid $stone-600;
  border-radius: 60px;
  padding: 0 12px;
  max-width: 357px;
  width: 100%;
}

option {
  height: 43px;
  line-height: 43px;
  background: $white;
  border: 1px solid $stone-600;
  padding: 0 12px;
}

//Pagination
.pagination {
  display: flex;
  justify-content: center;
  padding-top: 55px;
  gap: 12px;
  margin-top: 15px;

  li {
    border-radius: 44px;
  }

  a {
    display: flex;
    border: 2px solid $stone;
    width: 44px;
    height: 44px;
    border-radius: 24px;
    font-size: 14px;
    color: $voyage;
    padding: 0 12px;
    line-height: 38px;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &:hover {
      background: $stone-400;
      font-weight: 400;
    }
  }

  .active {
    background: $stone-600;
    pointer-events: none;
  }
}

@media screen and (max-width: 767px) {
  p,
  div,
  a,
  li,
  span {
    font-size: 14px;
    line-height: 21px;
  }

  i {
    font-size: 20px;
    line-height: 30px;
  }

  h1,
  .h1,
  .h1 p {
    font-size: 50px;
    line-height: 55px;
  }

  h2,
  .h2,
  .h2 p {
    font-size: 30px;
    line-height: 33px;
  }

  h3,
  .h3,
  .h3 p {
    font-size: 20px;
    line-height: 27px;
  }

  h4,
  .h4,
  .h4 p {
    font-size: 18px;
    line-height: 24.39px;
  }

  h5,
  .h5,
  .h5 p {
    font-size: 14px;
    line-height: 21px;
  }
}

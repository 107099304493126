.portal-banner-text {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  header {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 4px;

    h4 {
      color: $midnight-500;
    }
  }

  .introduction-text-wrapper {
    max-width: 500px;

    p {
      margin-bottom: 32px;
      font-size: 14px;
    }
  }
}

@media (min-width: 1024px) {
  .portal-banner-text {
    max-width: 380px;
    row-gap: 16px;
  }
}

.portal-page-title {
  background: $midnight;
  padding-top: 16px;
  padding-bottom: 16px;

  &.bg-voyage {
    background: $voyage;
  }

  @media (min-width: 768px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 32px;
    min-height: 240px;
  }

  .breadcrumbs,
  .description {
    color: $stone;
  }

  .breadcrumbs {
    line-height: 1.5;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 1.35;
    }
  }

  .heading {
    color: $breeze-300;
    font-size: 38px;
    line-height: 1.1;
    margin-bottom: 12px;

    @media (min-width: 768px) {
      font-size: 50px;
      line-height: 1;
    }
  }

  .description {
    font-size: 14px;
    line-height: 1.5;
  }

  &.secondary {
    background: $voyage-500;

    .heading,
    .breadcrumbs,
    .description {
      color: $white;
    }
  }
    .sub-heading {
        color: $white;
        font-size: 24px;
        line-height: 1.1;
        margin-bottom: 12px;
    }
}

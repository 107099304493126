.announcement-bar {
  background: #006282;
  color: white;

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 20px;

    @media screen and (min-width: 1090px) {
      padding: 16px 60px;
    }

    .content {
      a {
        color: #22e8f9;
        text-decoration: underline;
        text-decoration-color: #22e8f9;
      }
    }

    .icon {
      display: block;
      position: relative;
      background: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-left: 16px;

      > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: auto;
      }
    }
  }
}

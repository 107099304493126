#event-index-page {
  .row {
    padding-bottom: 32px;
  }
  .card-listing {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
    width: fit-content;
    width: 100%;
    max-width: 588px;

    .tag {
      background-color: $stone-300;
      border-radius: 2px;
      color: $midnight-300;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      padding: 4px 12px;
      text-transform: uppercase;
      width: fit-content;
      box-sizing: border-box;

      &.article {
        background-color: $white;
        color: $midnight-300;
        border: 1px solid $midnight-300;
        cursor: pointer;
      }
      &.article:hover {
        background-color: $stone-500;
        border-color: $midnight-500;
      }

      &.error {
        background-color: $error-red;
        color: $white;
      }

      &.success {
        background-color: $voyage;
        color: $white;
      }

      &.title.voyage {
        background-color: $voyage;
        color: $white;
      }
      &.title.grey {
        background-color: $stone-400;
        color: $midnight-300;
      }

      &.apply-now {
        background-color: $highlight;
        color: $midnight-500;
      }

      &.training-requested {
        background-color: $info-yellow;
      }

      &.training-in-cart {
        background-color: $attention-orange;
      }

      &.training-ready-to-download {
        background-color: $success-green;
      }

      &.article-feedback-required {
        background-color: $feedback-green;
        color: $white;
      }

      &.article-feedback-closed {
        background-color: $stone-700;
        color: $white;
      }

      &.article-for-the-attention-of,
      &.event-who-should-attend {
        background-color: $attention-orange;
      }
    }
  }

  .card-grouping {
    display: flex;
    flex-flow: column nowrap;
    gap: 48px;
  }

  .card {
    background: linear-gradient(to right, $voyage-300, $blue-background);
    box-shadow: -8px 8px 0px $blue-background;
    padding: 2px;
    width: 100%;
    max-width: 371px;

    &.no-shaddow {
      background: $border-medium;
      box-shadow: none;
      padding: 1px;
      min-height: 0;
    }

    &.small {
      .with-children-container {
        flex-direction: column;
      }
    }

    .columns {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      column-gap: 8px;

      @media screen and (max-width: 550px) {
        flex-direction: column;
      }

      .row {
        width: calc(50% - 4px);
        margin-right: 0;
        margin-left: 0;

        @media screen and (max-width: 550px) {
          width: 100%;
        }
      }
    }
  }

  .card__header {
    .tagged {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 8px;
      flex-flow: row wrap;
      max-height: 62px;
      overflow: hidden;
    }
    .title__container {
      display: flex;
      flex-direction: row;
      column-gap: 16px;

      .icon {
        margin-top: 2px;
      }

      .heading {
        display: flex;
        flex-direction: row;
        column-gap: 16px;
      }

      .title {
        width: 100%;
        display: flex;
        flex-direction: column;

        .h5 {
          color: $breeze-500;
        }

        .h4 {
          color: $midnight-500;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        a .h4:hover {
          color: $voyage-500;
        }
      }

      .tag {
        margin-top: 2px;
      }

      .with-children-container {
        display: flex;
        flex-direction: column;
      }
    }

    @media screen and (min-width: $desktop) {
      .title-container {
        .tag {
          margin-top: unset;
        }

        .with-children-container {
          flex-flow: row nowrap;
          justify-content: space-between;
        }
      }
    }
  }

  .card__layout {
    min-width: 120px;
    min-height: 219px;
    background: $white;
    display: flex;
    flex-direction: column;
    padding: 32px;
    row-gap: 16px;
    height: 100%;
  }

  .card__details {
    .row {
      align-items: center;
      border-bottom: 1px solid $stone-600;
      border-top: 1px solid $stone-600;
      column-gap: 8px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: -1px;
      padding: 8px 0;
      color: $midnight-300;

      &:last-of-type {
        border-bottom: 1px solid $stone-600;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    .text-meta {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .card__actions {
    display: flex;
    flex-flow: row wrap;
    column-gap: 32px;
    margin-top: auto;

    .btn-text {
      padding-left: 0;
    }

    .tag {
      margin: unset;
    }
  }

  .card__content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .load-more {
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;

    .meta {
      color: $stone-700;
    }
  }

  @media screen and (min-width: 673px) {
    .card {
      max-width: 588px;
    }
  }

  .portal-page-search {
    padding: 20px 16px 22px 16px;

    @media screen and (min-width: $tablet) {
      background: $stone-300;
      border: 1px solid $stone-500;
      border-radius: 2px;
      padding: 22px 29px 23px 29px;
    }
  }

  .portal-query-filters-container {
    display: flex;
    column-gap: 6px;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: $tablet) {
      flex-direction: row;

      & > * {
        max-width: 260px;
      }
    }
  }
  .searchbar {
    background: transparent;

    .searchbar-container {
      display: flex;
      width: 100%;
      column-gap: 8px;

      button {
        align-self: flex-end;
      }
      .btn-round {
        background-color: $voyage-500;
        height: 45px;
        position: relative;
        transition: background 200ms ease-out;
        width: 45px;
        border-radius: 50%;
        flex-shrink: 0;

        &:hover {
          background-color: $breeze-500;
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        svg path {
          fill: $white;
        }
      }
    }

    .form-field {
      margin-bottom: 0;
    }

    .results {
      color: $midnight-300;
      margin-top: 8px;

      .hightlight {
        font-weight: 700;
        color: $voyage-500;
      }
    }
  }
}

.global-search-panel {
  backdrop-filter: blur(30px);
  background: rgba($stone-300, 0.8);
  left: 0;
  mix-blend-mode: normal;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: -100%;
  transition: top 250ms ease-out, opacity 200ms ease-out;
  width: 100%;
  z-index: 2;

  &.is-open {
    top: 101%;
    opacity: 1;
    pointer-events: auto;
  }
}

.global-search-bar {
  background: $white;
  padding: 1em 20px;

  @media (min-width: 768px) {
    padding: 50px 0;
    background: rgba($stone-300, 0.8);


    > .row {
      text-align: left;
    }
  }

  @media (min-width: 1200px) {
    padding: 56px 0 67px 0;
  }

}

.global-search-results {
  .search-result-category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $stone-600;
    margin-bottom: 2em;

    .heading {
      display: flex;
      align-items: baseline;
      margin-bottom: 0.75em;

      h3 {
        margin-right: 1em;
        text-transform: capitalize;
      }

      .count {
        color: $stone-700;
        text-transform: uppercase;
        font-size: 12px;
      }

      .btn-text {
        margin-bottom: 0.5em;
      }
    }
  }
}


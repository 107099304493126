@import '../../utilities/variables.scss';

.label {
  display: block;
  color: $midnight-200;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 4px;
  text-transform: uppercase;
}

.article-callout {
  @extend .box;
  margin: 40px 0 0px;
  padding: 25px;

  .h4 {
    color: $voyage;
    margin: 0 0 20px;

    div {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .btn,
  .btn-primary {
    display: inline-flex;
    align-items: center;

    svg {
      margin: 0 0 0 10px;
    }
  }
}

// This file needs to sit outside of app.portal
// due to the menu being in a modal.

.portal-page-menu {
  border-bottom: 1px solid $stone-500;

  &.navigation {
    .side-menu {
      padding: 16px 20px 0 20px;
    }

    .collapsible.side-menu .collapsible-item:last-of-type {
      border-bottom: none;
    }
  }

  &.content .portal-page-menu__content {
    padding: 24px 20px 40px 20px;
  }

  &.content .portal-page-menu__content,
  &.navigation .side-menu {
    box-shadow: 0px 4px 9px rgba($stone-700, 0.4);
  }
  &.content .portal-page-menu__content,
  &.navigation .portal-page-menu__content {
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .collapsible-mobile-toggle {
    mix-blend-mode: normal;
    backdrop-filter: blur(30px);
    background: rgba($stone-300, 0.8);

    .icon {
      &::before,
      &::after {
        background: $voyage;
      }
    }
  }
}

.portal-page-menu__overlay {
  background-color: rgba($stone-500, 0.5);
  width: 100%;
  position: absolute;
}

.portal-page-menu__content {
  background: $white;
  display: flex;
  flex-direction: column;
  gap: 48px;

  .menu-card-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}

.portal-page-menu__item {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.portal-page-menu__item-content {
  .action-card,
  .contact-card,
  .export-card {
    max-width: 309px;
  }
}

@media screen and (min-width: 1023px) {
  .portal-page-menu {
    padding-right: 20px;
    border-bottom: none;

    &.content .portal-page-menu__content,
    &.navigation .side-menu {
      padding: unset;
      box-shadow: unset;
    }

    &.content .portal-page-menu__content,
    &.navigation .portal-page-menu__content {
      position: relative;
      z-index: auto;
      width: auto;
    }
  }

  .portal-page-menu__item {
    max-width: 309px;
  }

  .portal-page-menu__content {
    .collapsible-item:first-child {
      padding-top: 0;
    }
  }
}

#events-details-page{
    .card-listing {
        display: flex;
        flex-flow: column nowrap;
        gap: 24px;
        width: fit-content;
        width: 100%;
        max-width: 588px;

        .tag {
            background-color: $stone-300;
            border-radius: 2px;
            color: $midnight-300;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            padding: 4px 12px;
            text-transform: uppercase;
            width: fit-content;
            box-sizing: border-box;

            &.article {
              background-color: $white;
              color: $midnight-300;
              border: 1px solid $midnight-300;
              cursor: pointer;
            }
            &.article:hover {
              background-color: $stone-500;
              border-color: $midnight-500;
            }

            &.error {
              background-color: $error-red;
              color: $white;
            }

            &.success {
              background-color: $voyage;
              color: $white;
            }

            &.title.voyage {
              background-color: $voyage;
              color: $white;
            }
            &.title.grey {
              background-color: $stone-400;
              color: $midnight-300;
            }

            &.apply-now {
              background-color: $highlight;
              color: $midnight-500;
            }

            &.training-requested {
              background-color: $info-yellow;
            }

            &.training-in-cart {
              background-color: $attention-orange;
            }

            &.training-ready-to-download {
              background-color: $success-green;
            }

            &.article-feedback-required {
              background-color: $feedback-green;
              color: $white;
            }

            &.article-feedback-closed {
              background-color: $stone-700;
              color: $white;
            }

            &.article-for-the-attention-of,
            &.event-who-should-attend {
              background-color: $attention-orange;
            }
          }

      }

      .card-grouping {
        display: flex;
        flex-flow: column nowrap;
        gap: 48px;
      }

      .card {
        background: linear-gradient(to right, $voyage-300, $blue-background);
        box-shadow: -8px 8px 0px $blue-background;
        padding: 2px;
        width: 100%;
        max-width: 371px;

        &.no-shaddow {
          background: $border-medium;
          box-shadow: none;
          padding: 1px;
          min-height: 0;
        }

        &.small {
          .with-children-container {
            flex-direction: column;
          }
        }

        .columns {
          display: flex;
          flex-flow: row wrap;
          align-items: stretch;
          column-gap: 8px;

          @media screen and (max-width: 550px) {
            flex-direction: column;
          }

          .row {
            width: calc(50% - 4px);
            margin-right: 0;
            margin-left: 0;

            @media screen and (max-width: 550px) {
              width: 100%;
            }
          }
        }
      }

      .card__header {
        .tagged {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          margin-bottom: 8px;
          flex-flow: row wrap;
          max-height: 62px;
          overflow: hidden;
        }
        .title__container {
            display: flex;
            flex-direction: row;
            column-gap: 16px;

            .icon {
                margin-top: 2px;
            }

            .heading {
                display: flex;
                flex-direction: row;
                column-gap: 16px;
            }


            .title {
                width: 100%;
                display: flex;
                flex-direction: column;

                .h5 {
                    color: $breeze-500;
                }

                .h4 {
                    color: $midnight-500;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }

                a .h4:hover {
                    color: $voyage-500;
                }
            }

            .tag {
                margin-top: 2px;
            }

            .with-children-container {
                display: flex;
                flex-direction: column;
            }
        }

        @media screen and (min-width: $desktop) {
            .title-container {
                .tag {
                    margin-top: unset;
                }

                .with-children-container {
                    flex-flow: row nowrap;
                    justify-content: space-between;
                }
            }
        }
      }

      .card__layout {
        min-width: 120px;
        min-height: 219px;
        background: $white;
        display: flex;
        flex-direction: column;
        padding: 32px;
        row-gap: 16px;
        height: 100%;
      }

      .card__details {
        .row {
          align-items: center;
          border-bottom: 1px solid $stone-600;
          border-top: 1px solid $stone-600;
          column-gap: 8px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          margin-bottom: -1px;
          padding: 8px 0;
          color: $midnight-300;

          &:last-of-type {
            border-bottom: 1px solid $stone-600;
          }
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
        }

        .text-meta {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .card__actions {
        display: flex;
        flex-flow: row wrap;
        column-gap: 32px;
        margin-top: auto;

        .btn-text {
          padding-left: 0;
        }

        .tag {
          margin: unset;
        }
      }

      .card__content {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }

      .load-more {
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 16px;

        .meta {
          color: $stone-700;
        }
      }

      @media screen and (min-width: 673px) {
        .card {
          max-width: 588px;
        }
      }

      .staff-list {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 8px;
      }

      .staff-card,
      .contact-card {
        width: 100%;

        .card-wrapper {
          background: $stone-400;
          border-radius: 2px;
          border: 1px solid $stone-600;
          display: flex;
          gap: 16px;
          width: 100%;
        }

        .content {
          width: 100%;
        }

        .details,
        .contact {
          display: flex;
          flex-direction: column;
        }

        .details {
          padding-bottom: 8px;
        }

        .contact {
          border-top: 1px solid $stone-600;
          padding-top: 8px;
          row-gap: 4px;
        }

        h4 {
          color: $voyage;
        }

        h5 {
          color: $midnight-200;
          text-transform: uppercase;
        }

        p,
        a,
        span {
          color: $midnight-200;
          font-size: 12px;
          font-weight: 600;
          line-height: 135.5%;
          text-transform: uppercase;
        }

        .note {
          background-color: $white;
          margin-top: 6px;
          padding: 4px 12px;
          width: fit-content;
        }

        a {
          align-items: center;
          display: flex;

          .truncate {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          abbr[title] {
            border-bottom: none !important;
            cursor: inherit !important;
            text-decoration: none !important;
          }
        }

        .icon {
          align-items: center;
          background-color: $white;
          border-radius: 2px;
          display: inline-flex;
          flex-shrink: 0;
          height: 24px;
          justify-content: center;
          margin-right: 8px;
          width: 24px;

          svg {
            width: 75%;
          }
        }
      }

      .staff-card {
        max-width: 460px;
        .card-wrapper {
          padding: 24px;
        }
      }

      .contact-card {
        max-width: 309px;
        .card-wrapper {
          padding: 16px 24px;
        }
      }

    .tag.title.voyage {
        background-color: #006282;
        color: #ffffff;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        padding: 4px 12px;
        text-transform: uppercase;
        width: fit-content;
        box-sizing: border-box;
    }
    .action-card {
        background: $border-medium;
        border-radius: 2px;
        padding: 1px;

        &:hover {
            background: linear-gradient(to right, $stone-600, $midnight-200);
        }

        .layout {
            align-items: flex-start;
            background: $stone-400;
            border-radius: 1px;
            display: flex;
            justify-content: flex-start;
            padding: 16px;
            gap: 16px;

            .content {
                width: 100%;
                display: flex;
                flex-direction: column;

                .wrapper{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                }
            }
        }

        .title {
            color: $midnight-500;
            font-weight: bold;
            font-size: 14px;
            line-height: 150%;
        }

        .sub-title{
            color: $midnight-300;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            margin-bottom: 12px;
        }

        .value {
            color: $stone-700;
            flex-shrink: 0;
        }

        .link {
            font-size: 14px;
            line-height: 18px;
            text-decoration-line: underline;
            color: $voyage-500;
            font-weight: 400;
            font-style: normal;
            cursor: pointer;
            margin-top: 4px;

            &:hover {
                color: $breeze-500;
            }
        }

    }
    .portal-page-menu__content {
        padding: 24px 20px 40px 20px;
    }

    .article-wrapper,
.content-layout {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 690px;
  align-items: flex-start;
  margin-bottom: 60px;

  @media (min-width: $tablet) {
    gap: 48px;
  }

  .content-section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .action-card,
    .event-schedule-card {
      width: 100%;
      max-width: 588px;
    }
  }
}

.article-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    &:not(.btn) {
      text-decoration: underline;
      color: $voyage;
      display: inline;
    }
  }

  // img
  > img {
    width: 100%;
    margin: 12px 0px;
  }

  // video
  iframe {
    height: 56.25vw;
    margin: 12px 0px;
    max-height: calc(690px / 16 * 9);
    width: 100%;
  }

  // download card
  .download-wrapper {
    max-width: 390px;
    width: 100%;

    .download-card {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  // contact/staff card
  .staff-card,
  .contact-card {
    max-width: 460px;
    width: 100%;
  }

  // faq
  .accordion,
  .accordion-item {
    margin-bottom: 5px;
    width: 100%;
  }
}

}

.article-wrapper,
.content-layout {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 690px;
  align-items: flex-start;

  @media (min-width: $tablet) {
    gap: 48px;
  }

  .content-section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .action-card,
    .event-schedule-card {
      width: 100%;
      max-width: 588px;
    }
  }
}

.article-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    &:not(.btn) {
      text-decoration: underline;
      color: $voyage;
      display: inline;
    }
  }

  // img
  > img {
    width: 100%;
    margin: 12px 0px;
  }

  // video
  iframe {
    height: 56.25vw;
    margin: 12px 0px;
    max-height: calc(690px / 16 * 9);
    width: 100%;
  }

  // download card
  .download-wrapper {
    max-width: 390px;
    width: 100%;

    .download-card {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  // contact/staff card
  .staff-card,
  .contact-card {
    max-width: 460px;
    width: 100%;
  }

  // faq
  .accordion,
  .accordion-item {
    margin-bottom: 5px;
    width: 100%;
  }
}

// Mobile first styling

#contact-page {
    background: $white;
    padding: 0 0 150px;
    position: relative;

    // Varibale
    $complaint-bg-width-desktop: calc(100% - 117px);
    $complaint-bg-width-mobile: calc(100% - 20px);
    $footer-circle-dimensions-desktop: 388px;
    $footer-circle-dimensions-mobile: 192px;
    $grid-column-gap: 30px;

    // Extended
    .small-headings {
        color: $voyage;
        font-size: 14px;
        margin: 0 0 10px;
    }

    .contact-span {
        align-items: center;
        color: $midnight-300;
        column-gap: 8px;
        display: inline-flex;
        font-size: 14px;
        line-height: 24px;
        width: 100%;
    }


    .icon {
        margin-left: -5px;

        svg  path {
            fill: $voyage;
        }
    }

    //
    // Contact Section
    .contact-section {
        column-gap: $grid-column-gap;
        display: grid;
        grid-template-columns: 1fr;
        padding: 10px 20px;
    }

    .contact-sub-section {
        border-top: 3px solid $breeze;
        column-gap: $grid-column-gap;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        padding: 40px 0;
        row-gap: 20px;
    }

    .contact-item {
        flex: 1;

        h4 {
            margin: 0 0 10px;
        }

        h5, h6 {
            @extend .small-headings;
        }

        h6 {
            font-weight: 400;
            margin-top: -10px;
        }

        span {
            @extend .contact-span;
            display: inline-flex;
        }

        a {
            @extend .contact-span;

            &:hover {
                text-decoration: underline;
            }

            &.mailto-link {
                @extend .contact-span;
                color: $midnight-500;
                font-weight: 600;
                margin: 10px 0 0;
            }
        }
    }

    //
    // Complaints Section

    .contact-complaints {
        padding: 0 0 20px;
        position: relative;

        p,
        q {
            display: block;
            margin: 0 0 24px 0;
        }

        q {
            color: $voyage;
            font-size: 20px;
            font-style: italic;
            font-weight: 400;
            line-height: 30px;
        }

        h2 {
            grid-column: col-start / span 12;
        }

        .content-wrapper {
            column-gap: $grid-column-gap;
            display: grid;
            grid-template-columns: repeat(12, [col-start] 1fr);
            padding: 55px 75px 41px 30px;
            row-gap: 40px;
            width: 100%;
        }
    }

    // Separate bg from content to avoid different margins to above content
    .complaint-bg {
        background: $blue-background;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: $complaint-bg-width-mobile;
    }

    .complaint-content {
        grid-column: col-start / span 12;

        span {
            // display: block;
            margin: 0 0 5px;
        }
    }

    //
    // Footer
    .footer-circle {
        position: absolute;
        right: 0;
        width: $footer-circle-dimensions-mobile;
        bottom: -$footer-circle-dimensions-mobile;
        height: $footer-circle-dimensions-mobile * 2;
    }

    // Desktop styles
    @media screen and (min-width: $tablet) {

        .contact-details {
            padding-bottom: 80px;
            padding-top: 10px;
        }


        .contact-section {
            grid-template-columns: 4fr 6fr 2fr;
            padding: 0 60px;

            > h4 {
                margin-top: 40px;
            }
        }

        .contact-sub-section {
            flex-direction: row;
            margin-top: 0;
        }


        .contact-complaints {
            margin-top: 80px;

            .content-wrapper {
                padding: 98px 60px 119px 60px;
                row-gap: 51px;
            }

            h2 {
                grid-column: col-start / span 5;
            }
        }

        .complaint-bg {
            width: $complaint-bg-width-desktop;
        }

        .complaint-content {
            grid-column: 5 / span 6;

            q {
                font-size: 24px;
                line-height: 36px;
                margin: 40px 0;
            }

            .contact-sub-section {
                margin-top: 40px;
                padding-bottom: unset;
            }
        }

        .footer-circle {
            bottom: -$footer-circle-dimensions-desktop;
            height: $footer-circle-dimensions-desktop * 2;
            width: $footer-circle-dimensions-desktop;
        }
    }


    @media screen and (min-width: $desktop) {
        .complaint-content {
            grid-column: 5 / span 5;
        }
    }
}

.password-hints {
  color: $midnight-300;

  p {
    font-size: 14px;
    transition: color 300ms ease-out;
  }

  .password-hint {
    display: flex;
    column-gap: 8px;

    &.valid {
      p {
        color: $success;
      }

      .icon-tick {
        svg path {
          fill: $success;
        }
      }
    }

    .icon-tick {
      svg path {
        fill: $stone-600;
        transition: fill 300ms ease-out;
      }
    }
  }
}

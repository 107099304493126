.searchbar {
  background: transparent;

  .searchbar-container {
    display: flex;
    width: 100%;
    column-gap: 8px;

    button {
      align-self: flex-end;
    }
  }

  .form-field {
    margin-bottom: 0;
  }


  .results {
    color: $midnight-300;
    margin-top: 8px;

    .hightlight {
      font-weight: 700;
      color: $voyage-500;
    }
  }
}

.onboarding-form {
	position: relative;
	display: flex;
	flex-direction: column;
	background: $stone-300;
	padding: 0 !important;
	border-left: 1px solid $stone-500;

	@media (min-width: 1024px) {
		min-height: 100vh;
	}

	.container {
		@media (max-width: 1023px) {
			max-width: 500px;
		}
	}

	.onboarding-form-wrapper {
		margin-bottom: 42px;

		@media (min-width: 1024px) {
			margin: 0 auto 42px;
			max-width: 330px;
			width: 100%;

			.container {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
.portal-modal__overlay {
  background-color: rgba($stone-500, 0.5);
  inset: 0px;
  position: fixed;
  z-index: 99;
  
  &--filter {
    inset: 0px;
    position: fixed;
    z-index: 99;
    background-color: $white;
    overflow-y: scroll;
  }
}

.portal-modal {
  display: block;
  position: fixed;
  width: 588px;
  height: 400px;
  max-width: 90%;
  max-height: 90%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  .close {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 8px;

    .icon {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 24px;
      justify-content: center;
      width: 24px;

      svg path {
        fill: $midnight-500;
      }
    }

    .icon:hover {
      svg path {
        fill: $breeze-500;
      }
    }  
  } 
}

.modal-card {
  align-items: flex-start;
  background: $white;
  border: 2px solid $stone-500;
  box-shadow: 0px 4px 9px rgba($stone-700, 0.4);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 32px;
  row-gap: 32px;

  .modal-section {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    flex-grow: 1;
  }
}
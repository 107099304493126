.navbar {
  left: 0;
  width: 100%;
  height: auto;
  background: $midnight;
  color: $breeze;
  z-index: 8;

  &.navbar-top {
    top: 0;
    height: 30px;

    .attribution,
    .navbar-nav li a {
      font-size: 12px;
      font-weight: 600;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    .navbar-nav li:not(:last-child) {
      display: none;

      @media (min-width: 768px) {
        display: inline-block;
      }
    }

    .navbar-nav li a {
      &:hover {
        background: $highlight;
        color: $midnight-500;
      }
    }

    p,
    li,
    a {
      line-height: 24px !important;
    }
  }

  &.navbar-bottom {
    padding-bottom: 16px;

    @media (min-width: 768px) {
      padding-bottom: 0;
    }

    .content-wrapper {
      flex-direction: column;

      @media (min-width: 768px) {
        align-items: center;
        flex-direction: row;
      }
    }

    &.navbar-dot-nav {
      color: $white;

      .navbar-nav {
        li {
          margin-right: 20px;

          &:first-child {
            a {
              padding-left: 0;
            }
          }

          &:last-child {
            margin-right: 0;

            &::after {
              display: none;
            }

            a {
              padding-right: 0;
            }
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            right: -14px;
            top: calc(50% - 2px);
            border-radius: 50%;
            width: 4px;
            height: 4px;
            background: $white;
          }
        }

        a {
          color: $white;
        }
      }
    }

    div,
    a {
      font-size: 14px;
    }
  }

  .content-wrapper {
    padding: 0 28px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .attribution {
    padding: 16px 0;
  }

  .navbar-nav {
    position: relative;
    right: -5px;

    @media (max-width: 1023px) {
      right: -12px;
    }

    li {
      display: inline-block;
      position: relative;
    }

    a {
      color: $breeze;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.listing-counter {
	border-bottom: 1px solid $border-medium;
	color: $stone-700;
	font-size: 12px;
	font-weight: 600;
	line-height: 150%;
	margin: 20px 0;
	padding-bottom: 10px;
	text-transform: uppercase;

	@media screen and (min-width: $mobile) {
		margin: 16px 0 32px 0;
	}
}
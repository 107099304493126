@import '../../utilities/variables.scss';

.form-field {
  display: block;
  position: relative;
  margin-bottom: 16px;
  width: 100%;

  &.error {
    input,
    .text-input,
    .dropdown .dropdown-control {
      border-color: $error-red;
    }
  }
}

.pill {
  border: 2px solid $stone;
  height: 44px;
  border-radius: 24px;
  font-size: 14px;
  color: $voyage;
  padding: 0 12px;
  line-height: 38px;
  justify-content: center;
  text-decoration: none;
  background: $white;

  &.flat {
    background: $stone-400;
    border-radius: 2px;
    border: none;
    color: $midnight-300;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    height: auto;
    line-height: 150%;
    padding: 4px 12px;
    text-transform: uppercase;
  }

  &:hover {
    background: $stone-400;
  }

  &.active {
    background: $stone;
    pointer-events: none;
  }
}

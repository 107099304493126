.institutions-wrapper {
  margin-top: 60px;
}

.division-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;

  @media (min-width: 769px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.division {
  background: $white;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  max-width: 100%;
  border: 1px solid $stone-600;

  .header {
    height: 132px;
    border-bottom: 1px solid $stone-600;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 190px;
      max-height: 70px;
      width: auto;
    }
  }

  .blurb {
    color: $midnight-300;

    > table {
      width: auto !important;
      max-width: initial !important;
    }

    p {
      color: $midnight-300;
    }
  }

  .description {
    padding: 24px 28px;
    flex: 1;

    div,
    span,
    p,
    a {
      font-size: 14px;
    }

    h5 {
      color: $voyage;
    }

    p {
      margin: 10px 0 18px;
      color: $midnight-300;
    }

    a {
      color: $midnight;
      text-decoration: none;
      font-weight: 600;

      .icon {
        margin-left: 9px;
        transition: 0.3s;
      }

      &:hover {
        .icon {
          transform: translateX(9px);
          transition: 0.3s;
        }
      }
    }
  }

  .footer {
    margin: 0 -1px -1px -1px;

    .offers {
      border: 1px solid $stone-600;
      border-top: 0;
      background: $voyage;
      margin-top: auto;
      padding: 16px;
      color: $stone-300;
      min-height: 130px;

      img {
        width: 21px;
      }

      .footer-heading {
        display: flex;
        align-items: center;
        column-gap: 12px;
      }

      ul {
        margin-left: 32px;
        margin-top: 6px;
        list-style-type: disc;
      }

      li {
        font-size: 14px;
        line-height: 23px;
        display: flex;
        list-style: none;

        &:before {
          content: '•';
          color: $breeze-300;
          font-size: 14px;
        }

        span {
          padding-left: 9px;
          line-height: 21px;
        }
      }
    }

    .row,
    span,
    p,
    a {
      font-size: 14px;
      line-height: 150%;
    }

    .contact-details {
      background: $stone;
      color: $midnight-200;
      padding: 14px 17px 14px 17px;
      height: 13.5rem; // 9 lines * 1.5 line height
      overflow-y: scroll;    }

    .row {
      display: flex;
      align-items: flex-start;
      column-gap: 7px;
      flex-flow: nowrap row;
    }

    .icon {
      path {
        fill: $midnight-300;
      }
    }

    .distance {
      font-size: 12px;
    }

    .standard {
      text-decoration: underline;
      color: $voyage;
    }
  }
}

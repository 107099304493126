.H1 {
  font-weight: bold;
  font-size: 38px;
  line-height: 1.1;

  @media (min-width: 1024px) {
    font-size: 50px;
    line-height: 1;
  }
}

.H2 {
  font-weight: bold;
  font-size: 26px;
  line-height: 1.1;

  @media (min-width: 1024px) {
    font-size: 35px;
  }
}

.H3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.35;

  @media (min-width: 1024px) {
    font-size: 25px;
  }
}

.H4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.35;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
}

.H5 {
  font-weight: bold;
  font-size: 10px;
  line-height: 1.5;

  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 1.35;
  }
}

.P {
  font-size: 14px;
  line-height: 1.5;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}

.Quote {
  font-style: italic;
  font-size: 20px;
  line-height: 1.45;

  @media (min-width: 1024px) {
    font-size: 24px;
  }
}

.meta {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
}

.text-link {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: $voyage;
  transition: color 300ms ease-out;

  &:hover {
    color: $voyage-700;
  }
}

.onboarding-form-wrapper {
	.btn-form-back {
		margin: 0;
		padding: 0;

		.icon {
			margin: 0 10px 2px 0;

			svg {
				transform: translateX(0);
				transition: transform 300ms ease-out;

				path {
					fill: $midnight;
				}
			}
		}

		&:hover {
			.icon {
				svg {
					transform: translateX(-5px);

					path {
						fill: $breeze;
					}
				}
			}
		}
	}
}
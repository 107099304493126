.staff-image {
  background-color: $stone-500;
  display: flex;
  flex-shrink: 0;
  height: 142px;
  width: 114px;

  img {
    border-radius: 2px;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .placeholder {
    align-items: center;
    border: 1px solid $stone-600;
    border-radius: 2px;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    .mini-logo-common {
      margin: auto;
    }
  }
}

.portal-page-search {
  padding: 20px 16px 22px 16px;
  @media screen and (min-width: $tablet) {
    background: $stone-300;
    border: 1px solid $stone-500;
    border-radius: 2px;
    padding: 22px 29px 23px 29px;
  }
}

.portal-query-filters-container {
  display: flex;
  column-gap: 6px;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: $tablet) {
    flex-direction: row;

    & > * {
      max-width: 260px;
    }
    &.events {
      margin-top: 24px;
    }
  }
  &.events {
    margin-top: 24px;
  }
}
.vacancies {
  .portal-query-filters-container {
    margin-top: 16px;
    column-gap: 24px !important;
    @media screen and (min-width: $tablet) {
      margin-top: 24px;
    }
  }
}

.accordion-item {
  display: flex;
  flex-direction: column;
  padding: 18px 0;

  border-top: 1px solid $stone-600;

  &:last-of-type {
    border-bottom: 1px solid $stone-600;
  }

  p {
    color: $midnight;
    &.bold {
      font-weight: 700;
    }

    &.small,
    &.small p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .accordion {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    padding: 0 16px;
    margin: 0;
    min-height: 48px;

    .accordion-title {
      color: $voyage;
    }
    .icon {
      flex: 0 0 20px;
      margin-left: auto;
    }
  }

  .accordion-content {
    overflow: hidden;
    transition: max-height 0.4s cubic-bezier(0.4, 0, 0.4, 0.99);

    .accordion-text {
      margin-top: 8px;
      padding: 0 16px;
    }
  }

  button {
    display: flex;
    width: 100%;

    gap: 20px;
    font-weight: 700;
    text-align: left;
  }
}

.preferences {
  background: $stone-300;
  border-radius: 5px;
  border: 1px solid $border;
  display: flex;
  flex-direction: column;
  padding: 32px;
  row-gap: 16px;
  width: 100%;

  h4 {
    color: $voyage-700;
  }

  &.bg-stone {
    background: $stone-300;
  }
}

.title__container {
	display: flex;
	flex-direction: row;
	column-gap: 16px;

	.icon {
		margin-top: 2px;
	}

	.heading {
		display: flex;
		flex-direction: row;
		column-gap: 16px;
	}


	.title {
		width: 100%;
		display: flex;
		flex-direction: column;

		.h5 {
			color: $breeze-500;
		}

		.h4 {
			color: $midnight-500;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}

		a {font-size: inherit; font-weight: inherit; line-height: inherit;}
		

		a .h4:hover, a:hover  {
			color: $voyage-500;
		}
	}

	.tag {
		margin-top: 2px;
	}

	.with-children-container {
		display: flex;
		flex-direction: column;
	}
}

@media screen and (min-width: $desktop) {
	.title-container {
		.tag {
			margin-top: unset;
		}	

		.with-children-container {
			flex-flow: row nowrap;
			justify-content: space-between;
		}
	}
}
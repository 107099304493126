.feedback.notice,
.feedback.action {
  background: linear-gradient(180deg, $feedback-green 0%, $breeze-300 100%);

  &.large {
    max-width: 588px;
  }
  &.small {
    max-width: 329px;
  }

  .layout {
    background-color: $stone-300;
    gap: 16px;
    margin-left: 5px;
    padding: 32px;
    display: flex;
    flex-direction: column;
  }

  .title {
    color: $voyage;
  }

  .btn {
    &.btn-text {
      padding-left: 0;
    }
    .icon-left {
      max-width: 20px;
    }
  }
}

.department-listing {
	display: flex;
	flex-flow: column nowrap;	
}

.department {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	margin-bottom: 24px;
}

.staff-card-listing {
	display: flex;
	flex-flow: column nowrap;
	gap: 8px;
}
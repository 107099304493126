.hero-section {
  background: $blue-background;
  padding-top: 70px;

  .caption,
  .breadcrumbs {
    @extend .breadcrumbs-base;
  }

  .content-wrapper {
    padding: 40px 20px;
  }

  .page-description {
    margin: 60px 0 0 0;
    display: flex;
  }

  @media screen and (max-width: $tablet) {
    .page-description {
      gap: 14px;

      .mini-logo-common {
        position: unset;
        left: unset;
        margin: unset;
      }
    }

    .breadcrumbs {
      display: none;
    }
  }

  @media screen and (min-width: $desktop) {
    padding-top: 124px;

    .content-wrapper {
      padding: 45px 60px 70px 60px;
    }
  }
}

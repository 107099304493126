.form.my-account.update-division-details {
  .logo-preview-img {
    margin-bottom: 1em;
  }

  .division-logo-btns {
    .btn-fill {
      position: relative;

      .input-upload-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.checkbox-wrapper {
  position: relative;
  line-height: 1.5;
  font-size: 14px;

  .icon {
    position: absolute !important;
    top: -1.25px;
    z-index: 2;
    left: -4px;
    transform: scale(0);
    transition: transform 200ms ease-out;
    pointer-events: none;

    svg path {
      fill: $white;
    }
  }
}

.checkbox-wrapper {
  .checkbox {
    max-width: initial;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  .checkbox,
  .checkbox-label::before {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 2.5px;
    cursor: pointer;
  }

  .checkbox-label {
    display: inline-block;
    position: relative;
    padding: 0 0 0 24px;
    vertical-align: top;
    color: $midnight;
    cursor: pointer;
    transition: color 0.3s;
    pointer-events: none;
  }

  .checkbox-label::before {
    content: '';
    display: block;
    opacity: 1;
    box-shadow: inset 0 0 0 2px transprent;
    border: 1px solid $stone-700;
    transition: box-shadow 200ms ease-out, border 200ms ease-out;
    box-sizing: border-box;
    background-color: $white;
  }

  > .checkbox:checked {
    & + label::before {
      box-shadow: inset 0 0 0 8px $breeze-300;
      border: 1px solid $breeze;
    }

    & ~ .icon {
      transform: scale(0.5833);
    }
  }
}

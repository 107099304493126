.event-schedule-card {
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  box-sizing: border-box;
  align-items: stretch;

  .time {
    align-items: center;
    border-radius: 2px 0 0 2px;
    color: $white;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 90px;
    justify-content: center;
    width: 100px;
  }

  .content,
  .location {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .content {
    overflow: hidden;
    flex-grow: 1;
    padding-left: 32px;

    .heading {
      color: $breeze-500;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .location {
    border-radius: 0 2px 2px 0;
    flex-shrink: 0;
    padding-left: 24px;
    padding-right: 24px;
  }

  &.event {
    background-color: $white;

    .time {
      background-color: $breeze-600;
    }

    .location,
    .content {
      border-top: 1px solid $stone-600;
      border-bottom: 1px solid $stone-600;
    }
    .location {
      border-right: 1px solid $stone-600;
    }
  }

  &.break {
    background-color: $stone-400;

    .time {
      background-color: $breeze-300;
    }
  }
}

#key-metrics {
  background: $blue-background url('/images/half_logo.png') fixed no-repeat;
  background-position: 0 15vh;
  background-size: 181px 361px;
  z-index: 1;

  h2,
  h3 {
    flex: 0 1 162px;
    line-height: 110%;
  }

  h2 {
    font-size: 60px;
  }

  h3 {
    font-size: 30px;
  }

  .content-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 40px 60px 70px 60px;
  }

  .metrics-wrapper {
    display: flex;
    flex-direction: column;
    gap: 45px;

    .metric {
      width: 100%;
    }
  }

  .logo-wrapper {
    padding: 0 100px 0 0;
  }

  .logo {
    margin-bottom: 48px;
    width: 90px;
  }

  .icon {
    height: 80px;
    width: 80px;
  }

  .caption {
    background-color: #d5f0f3;
    color: $voyage;
    font-size: 20px;
    line-height: 123%;
  }

  .heading {
    align-items: center;
    border-bottom: 1px solid $stone-600;
    display: flex;
    font-size: 65px;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;

    span {
      margin-left: 3vw;
      flex: 0 0 auto;
    }
  }

  @media screen and (min-width: $tablet) {
    background-size: 388px 775px;

    .content-wrapper {
      align-items: center;
      flex-direction: row;
      padding: 100px 60px 169px 60px;
    }

    .logo-wrapper {
      padding: 0 100px 0 100px;
    }

    .logo {
      width: 243px;
      z-index: 1;
    }

    .metrics-wrapper {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 70px 10px;
      justify-content: space-between;
      max-width: 750px;
      position: relative;

      .metric {
        min-width: 250px;
        max-width: 310px;
        width: calc(50% - 10px);
      }
    }
  }
}

// All carousel styles should be moved to this common sheet (rather than repeated for every carousel)
.carousel {
    clip-path: none;
    display: flex;
    flex-direction: column-reverse;
    row-gap: 25px;
    padding: 0 30px;

    &.carousel-with-quote-header {
      padding: unset;
      row-gap: 30px;
      flex-direction: column;
    }
}

.carousel-conatainer{
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &.carousel-with-quote-header {
    flex-direction: column;

    justify-content: flex-end;
    column-gap: 30px;
    clip-path: inset(0 -100vw 0 0);

    .controls-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .controls {
      flex-direction: row;
    }
  }
}

.carousel-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  
    .heading {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      column-gap: 16px;
  
      .mini-logo-common {
        margin: unset;
      }
    }
    .heading {
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
}

.controls-container {
  z-index: 1;

  .controls {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    row-gap: 50px;

    .btn {
      height: 45px;
      width: 45px;
      padding: unset;
      border-radius: 50%;
    } 

    .btn-group {
      gap: 8px;
    }

  }

  .index-bars {
    display: flex;
    gap: 8px;
    align-items: center;
    padding-left: 0px; 
  }

  .carousel-indicator {
    width: 36.66px;
    height: 3px;
    background-color: $stone-600;
    border-radius: 2px;
    list-style: none;
    transition: 0.3s;

    &.active {
      background-color: $breeze;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    column-gap: 14px;
  }

  .btn-active {
    border: 1px solid $breeze;
    border-radius: 50%;

    &:hover {
      border: 1px solid $voyage-400;
      path {
        fill: $stone-500;
      }
    }
  }

  .btn-disabled {
    pointer-events: none;
    border: none;

    path {
      fill: $stone-800;
      transition: .3s;
    }
  }

  .breadcrumbs {
    display: flex;
    gap: 3px;
    flex-wrap: wrap;

    .icon {
      width: 36.66px;
    }
  }

  .active-breadcrumb {
    path {
        stroke: $breeze;
        transition: .3s;
    }
  }
}

.slide-container {
  display: flex;
  gap: 30px;
  transition: 0.3s;

  .slide {
    // width: calc(50% - 15px);
    max-width: 382px;
    transition: 0.3s ease-out;
    flex-shrink: 0;
  }
 
  .hide-slide {
    opacity: 0;
    pointer-events: none;
  }
}


@media (min-width: $tablet) {
  .carousel {
    padding: 60px 60px 30px 60px;
    row-gap: 50px;
  }

  .carousel-conatainer {
    &.carousel-with-quote-header {
      flex-direction: row-reverse;
    }
  }

  .controls-container {
    .controls {
      .btn {
        height: 65px;
        width: 65px;
      }
    }

    .carousel-indicator {
      width: 50px;
    }

    .breadcrumbs {
      gap: 4px;
  
      .icon {
        width: 56px;
      }
    }
  }

  .slide-container {  
    .slide {
      flex: 1 0 587px;
      max-width: 587px;
    }
  }
}
#key-benefits {
  margin-top: 206px;
  // background: url("/images/half-logo.png") no-repeat left top 451px;
  position: relative;
  overflow: hidden;

  .background-half-logo {
    position: absolute;
    left: 0;
    bottom: -415px;
  }

  header {
    padding: 0 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .heading {
    max-width: 739px;
    display: flex;
  }

  .card-container {
    display: flex;
    @include emulated-gap(30px);
    padding: 0 60px 160px;
    margin-top: 80px;
    flex-wrap: nowrap;
  }

  .card {
    border-top: 3px solid $breeze;
    background: rgba($stone-300, 0.8);
    backdrop-filter: blur(50px);
    flex: 1 1 auto;
    padding: 30px 30px 65px;
    //
    position: relative;
    overflow: hidden;

    .card-heading {
      display: flex;
      justify-content: space-between;
      height: 175px;
      z-index: 1;
      position: relative;
    }

    .card-content {
      z-index: 1;
      position: relative;
    }

    .blurred-background {
      background: rgba($stone-300, 0.8);
      filter: blur(30px);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 0;
      display: none; //currently hidding this non-chrome workaround
    }

    h3 {
      color: $voyage;
      margin-bottom: 12px;
    }
  }

  .icon {
    height: 75px;
    width: 75px;
  }

  @media screen and (max-width: 767px) {
    .background-half-logo {
      width: 85%;
      bottom: -80vw;
    }

    header {
      padding: 0 20px 0 35px;
      flex-direction: column;
      position: relative;
      align-items: flex-start;
    }

    .heading {
      margin-bottom: 40px;
    }

    .card-container {
      flex-direction: column;
      padding: 0 20px 160px;
      margin-top: 60px;
    }
  }
}

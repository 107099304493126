.portal-modal--filter {
  .collapsible-mobile-toggle {
    &.filter.active {
			background: $voyage-500;
			color: $white;

			.icon {
				&::before,
				&::after {
					background: $white;
				}
        
        svg path {
          fill: $white;
        }
			}
		}
  }


	.radio-button-wrapper {
		margin: 8px 0;

		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.portal-page-menu__item-content {
		padding: 24px 20px;
	}

	.collapsible-item:last-of-type {
		border-bottom: none;
		padding-bottom: 0px;
	}
}
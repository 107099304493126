.ceo-desk {
	background: linear-gradient(to right, $stone-300, $stone-300 40%, $white 40%, $white 100%);
	border-bottom: 1px solid $border;
	border-top: 1px solid $border;

	@media (max-width: 767px) {
		background: $white;
	}

	.container {
		@media screen and (max-width: 767px) {
			padding: 0;
		}
	}
	
	.ceo-info,
	.article-excerpt {
		padding: 92px 20px 92px 116px;

		@media screen and (max-width: 767px) {
			padding: 40px 20px;
		}
	}

	.ceo-info {
		background: $stone-300;
		display: flex;
		padding-left: 0;

		@media screen and (max-width: 767px) {
			padding: 40px 20px;
		}

		>.mini-logo-common {
			flex: none;
			margin-top: 2px;

			@media screen and (max-width: 767px) {
				align-items: center;
				display: flex;
				justify-content: center;
				left: 0;
				position: relative;
			}
		}

		.attribution {
			.H5 {
				color: $breeze;
				text-transform: uppercase;

				@media (max-width: 1023px) {
					font-size: 14px;
				}
			}

			.H3 {
				@media (max-width: 1023px) {
					font-size: 20px;
				}
			}

			@media (max-width: 767px) {
				align-items: center;
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
		}
	}

	.staff-image {
		margin-top: 30px;

		@media (max-width: 767px) {
			margin-top: 0;
		}
	}

	.article-excerpt {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		max-width: 750px;
		gap: 32px;

		.excerpt {
			span,
			p {
				font-size: 14px;
				line-height: 21px;
				padding-bottom: 21px;

				&:last-child {
					padding-bottom: 0;
				}
			}

			blockquote {
				border-left: 5px solid $highlight;
				color: $voyage-700;
				font-size: 20px;
				font-style: italic;
				font-weight: 400;
				line-height: 30px;
				margin-bottom: 32px;
				padding-left: 24px;
			}
		}

	}
}

.signature {
	width: unset;
	display: unset;
}	
#discover-members {
  padding-top: 41px;
  padding-bottom: 82px;
  min-height: 300px;
  overflow-x: hidden;
  position: relative;

  .content {
    $gutter: 118px;
    padding: 108px 60px;
    width: calc(100% - #{$gutter});
  }

  header {
    padding: 0 30px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    row-gap: 28px;
    padding-bottom: 28px;
  }

  .heading {
    display: flex;
  }

  .carousel-conatainer {
    flex-direction: column-reverse;
    column-gap: 30px;
  }

  .discover-division {
    background: $white;
    border: 1px solid $stone;
    height: 71px;
    width: 149px;

    a {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }

    img {
      max-width: 109px;
      max-height: 55px;
      width: auto;
    }
  }

  .slide-container {
    display: flex;
    gap: 5px;
    z-index: 0;

    .hide-slide {
      opacity: 0;
      pointer-events: none;
    }

    .slide {
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 5px;
      transition: 0.3s;
      flex-shrink: 1;
    }
  }

  .controls {
    flex-flow: row;
  }

  .controls-container {
    .btn-disabled {
      pointer-events: none;
      border: 0;
      border-radius: 50px;
    }

    .active-breadcrumb {
      svg {
        max-width: 56px;
        width: 100%;
      }

      path {
        stroke: $breeze;
        transition: 0.3s;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    header a,
    .slide-container,
    .controls-container {
      margin-left: 75px;
    }

    header {
      padding: 0 60px;
      flex-direction: row;
      justify-content: space-between;
    }

    .discover-division {
      height: 130px;
      width: 275px;

      img {
        max-width: 190px;
        max-height: 70px;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    .carousel-conatainer {
      flex-direction: row;
    }

    header {
      padding: unset;
      flex-direction: column;
    }

    .slide-container,
    .controls-container {
      margin-left: unset;
    }

    .controls-container {
      flex: 1 0 390px;
      flex-direction: column;
      display: flex;
      justify-content: space-between;
    }

    .controls {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-left: 75px;
    }
  }
}

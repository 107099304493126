.institutions-hero-section {
  background: $blue-background;

  .content-wrapper {
    display: flex;
    align-items: center;
    padding: 124px 20px 60px;
    overflow-x: clip;
  }

  .heading {
    flex: 0 0 50%;
    z-index: 1;
    align-items: center;

    h1 {
      margin: 8px 0 28px;
    }
  }

  .parallax-image {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -80px;

    .bg-img {
      object-fit: cover;
    }

    .logo {
      width: 36vw;
      margin-top: -43%;
      position: absolute;
      min-width: 335px;
      right: -23px;
      bottom: -355px;
      transform: translateY(85px);
    }

    .image {
      border-radius: 294px;
      overflow: hidden;
      max-width: 588px;
      width: 36vw;
      max-height: 588px;
      height: 36vw;
      position: relative;
      z-index: 1;
      transition: 0.15s;
      transform: translate(-30px, 70px);
    }

    .transparent-logo {
      position: absolute;
      width: 50%;
      top: 0;
      mix-blend-mode: lighten;
    }
  }

  @media screen and (max-width: $tablet) {
    .breadcrumbs {
      display: none;
    }
  }

  @media screen and (min-width: $desktop) {
    .heading {
      h1 {
        margin: 8px 0 40px;
      }
    }

    .parallax-image {
      .logo {
        bottom: -275px;
        max-width: 588px;
      }

      .image {
        transform: translate(60px, 60px);
      }
    }

    .content-wrapper {
      padding: 124px 60px 60px;
    }
  }
}

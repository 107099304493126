.cta-card {
	padding: 24px;
	background-color: $stone-300;
	border-radius: 5px;
	border: 1px solid $stone-600;
	text-align: center;
	width: 100%;

	.cta-card-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		row-gap: 16px;
		max-width: 460px;
		margin: 0 auto;
	}
}
.institutions-filters {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (min-width: $desktop) {
    grid-template-columns: repeat(4, 2fr);
  }

  label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 17px;
    display: block;
  }

  .speciality {
    flex: 0 1 415px;
  }

  ul {
    display: flex;
    @include emulated-gap(15px);
  }

  li {
    flex: 0 1 200px;
  }

  .pill {
    width: 100%;
    text-align: center;

    &.active {
      pointer-events: all;
    }
  }

  .location {
    display: flex;
    align-items: flex-end;
  }

  .radius {
    .select {
      border: 2px solid #e9e9ea;
      border-radius: 2px;

      div {
        padding: 0 2px;
      }
    }
  }

  .overlay {
    position: fixed;
    background: rgba($midnight-700, 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dialog {
    min-width: 300px;
    background: $background;
    padding-bottom: 20px;

    .dialog-header {
      display: flex;
      justify-content: flex-end;
      padding: 0 5px 0 20px;

      button {
        border: 0;

        &:hover {
          background: none;
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }

    .dialog-body {
      padding: 20px;
    }
  }

  .form-location {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10px 0 0;

    label {
      flex: 1 100%;
    }

    input {
      border: 2px solid #e9e9ea;
      border-radius: 2px;
      flex: 1;
    }
  }

  .geo-locate {
    flex: 0 0 43px;
    height: 43px;
    border-radius: 43px;
    background: $breeze-700;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 22px;
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .institutions-filters {
    ul {
      flex-wrap: wrap;
    }

    li {
      flex: 1 0 auto;
    }

    .speciality {
      flex: auto;
    }

    .location {
      flex: auto;
    }

    .radius {
      flex: auto;
    }
  }
}

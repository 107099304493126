//Primary Colours
$midnight: #011a2f;
$midnight-200: #4b5d6c;
$midnight-300: #354859;
$midnight-400: #1b3143;
$midnight-500: #001B31;
$midnight-600: #021729;
$midnight-700: #011526;

$voyage: #006282;
$voyage-300: #5793a8;
$voyage-400: #23728e;
$voyage-500: #006282;
$voyage-600: #0b5874;
$voyage-700: #0a4e67;

$breeze: #22b7c4;
$breeze-300: #7ad4dd;
$breeze-400: #44bcc9;
$breeze-500: #22B7C4;
$breeze-600: #2ca3af;
$breeze-700: #27929c;

$stone: #E9E9EA;
$stone-300: #f8f8f8;
$stone-400: #f5f5f5;
$stone-500: #E9E9EA;
$stone-600: #d0d0d1;
$stone-700: #b9b9ba;
$stone-800: #bababa;

//Secondary Colours
$highlight: #22e8f9;
$background: #ececec;
$blue-background: #d5f0f3;
$white: #ffffff;

//Functional Colours
$error: #a44f55;
$success: #86d378;
$success-green: #ade1a3;
$error-red: #ed636c;
$attention-orange: #fdaf60;
$feedback-green: #4fa495;
$info-yellow: #fcf188;

//Font Family
$primary-font: soleil, sans-serif;

$border: $stone;
$border-medium: $stone-600;
$border-dark: $midnight-500;

// Breakpoints
$mobile: 411px;
$tablet: 768px;
$desktop: 1024px; 
@import '../../utilities/variables.scss';

.text-input {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 12px 16px;
  border-radius: 2px;
  border: 1px solid $stone-600;
  height: auto;
  color: $midnight;
  transition: border 400ms ease-out;
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
  font-family: soleil, sans-serif;

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  &:hover,
  &:focus {
    border-color: $midnight-300;
  }

  &:hover {
    & + .icon {
      background: $breeze;
    }
  }

  &:focus {
    & + .icon {
      background: $voyage;
    }
  }

  &::placeholder {
    color: $stone-600;
  }

  &:disabled {
    color: $midnight-400;
    background: $stone-300;
  }
}

.text-input-icon-wrapper {
  display: flex;
}

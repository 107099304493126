@import '../utilities/variables.scss';

.icon {
  flex-shrink: 0;
  align-items: center;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  &.midnight {
    svg path {
      fill: $midnight-500;
    }
  }

  &.voyage {
    svg path {
      fill: $voyage-500;
    }
  }

  &.breeze {
    svg path {
      fill: $breeze-500;
    }
  }

  &.white {
    svg path {
      fill: $white;
    }
  }

  &.stone {
    svg path {
      fill: $stone-700;
    }
  }

  &.bg-white {
    background-color: $white;
  }

  &.small {
    height: 24px;
    width: 24px;

    svg {
      height: 67%;
    }
  }

  &.outline {
    border: 1px solid $border;
    height: 40px;
    width: 40px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    background: $white;
  }

  &.icon-outline {
    width: 50px;
    height: 50px;
    background: $white;
    border: 1px solid $breeze;
    transition: background 200ms ease-out, border 200ms ease-out;

    svg {
      path {
        fill: $midnight;
      }
    }
  }

  &.icon-outline {
    flex-shrink: 0;

    svg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }

  &.icon-chevron-up-down,
  &.icon-chevron-down-up {
    &.voyage {
      &::before,
      &::after {
        background: $voyage;
      }
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      transition: transform 200ms ease-out, border 200ms ease-out;
      width: 2px;
      height: 8px;
      background: $breeze;
    }
  }

  &.icon-chevron-down-up {
    &::before {
      transform: translateX(-2px) rotate(-135deg);
    }

    &::after {
      transform: translateX(-8px) rotate(135deg);
    }

    &.active {
      &::before {
        transform: translateX(-2px) rotate(-45deg);
      }

      &::after {
        transform: translateX(-8px) rotate(45deg);
      }
    }
  }

  &.icon-chevron-up-down {
    &::before {
      transform: translateX(-2px) rotate(135deg);
    }

    &::after {
      transform: translateX(-8px) rotate(-135deg);
    }

    &.active {
      &::before {
        transform: translateX(-2px) rotate(45deg);
      }

      &::after {
        transform: translateX(-8px) rotate(-45deg);
      }
    }
  }

  &.icon-plus-minus,
  &.icon-minus-plus {
    &.voyage {
      &::before,
      &::after {
        background: $voyage;
      }
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      transition: transform 200ms ease-out, border 200ms ease-out;
      background: $breeze;
    }
  }

  &.icon-plus-minus {
    &::before {
      width: 2px;
      height: 12px;
    }

    &::after {
      width: 12px;
      height: 2px;
    }

    &.active {
      &::before {
        transform: rotate(90deg);
      }

      &::after {
        transform: rotate(180deg);
      }
    }
  }
}

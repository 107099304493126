.input-password-wrapper {
  position: relative;

  .icon-passowrd-show-wrapper {
    position: absolute;
    top: calc(50% - 8px);
  }

  .icon-passowrd-hide-wrapper {
    position: absolute;
    top: calc(50% - 8px);
  }

  .show-password-toggle {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    outline: none;
    border: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

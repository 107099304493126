@import './institutions-hero.scss';
@import './institutions-filter.scss';
@import './institutions-list.scss';

#find-a-bank {
    .page-content {
        &.content-wrapper {
            padding: 60px;
        }
    }

    .num-inst-displayed {
        margin-bottom: 20px;
    }

    @media screen and (max-width:767px) {
        .page-content {
            &.content-wrapper {
                padding: 60px 20px;
            }
        }
    }
}

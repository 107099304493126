.main-footer {
  margin-top: auto;
  position: relative;
  overflow: hidden;
  background: $blue-background;

  .dark-background {
    background: $voyage-500;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 243px;
  }

  .content-wrapper {
    position: relative;
  }

  .container {
    display: grid;
    column-gap: 20px;
    row-gap: 64px;
    padding-bottom: 64px;

    h4 {
      color: $voyage-700;
    }
  }

  .socials,
  .member-portal,
  .contact-us,
  .subscribe .content {
    display: flex;
    flex-direction: column;
    row-gap: 28px;
  }

  .customer-support,
  .contact-us,
  .socials {
    a {
      color: $midnight;
      text-decoration: none;
    }
  }

  .contact-us {
    ul {
      display: flex;
      flex-direction: column;
    }
  }

  .socials {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
    }

    .icon {
      margin-right: 12px;
    }
  }

  .socials,
  .contact-us {
    ul {
      row-gap: 20px;
    }
  }

  .subscribe {
    grid-row-start: 1;
    padding: 32px 0;

    h4,
    .content {
      color: $stone;
    }

    .content {
        row-gap: 20px;
    }

    .form-field {
      margin-bottom: unset;
    }
  }

  .copyright {
    background: $midnight;
    color: $stone;
    font-size: 14px;
    position: relative;
    z-index: 1;

    .content-wrapper {
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div:first-of-type {
        margin: 0 0 20px;
      }
    }

    a {
      color: $stone;
    }

    .icon {
      padding: 0 14px;

      svg {
        margin-bottom: 3px;
      }
    }
  }

  @media screen and (min-width: $tablet) {

    .container {
      column-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      padding-bottom: unset;
      row-gap: unset;
    }

    .socials,
    .member-portal,
    .contact-us,
    .subscribe {
      padding: 64px 0;
    }

    .contact-us {
      grid-column: 1;
    }

    .socials {
      grid-column: 2;

      ul {
        grid-template-columns: auto;
      }
    }
    
    .member-portal {
      grid-column: 3;
    }

    .subscribe {
      max-width: none;
      grid-column: 1 / span 3;

      .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;

        h4 {
          grid-column: 1 / span 2;
        }
      }
    }

    .copyright {
      .content-wrapper {
        padding: 16px 20px;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    .dark-background {
      left: 63%;
      height: 100%;
    }

    .socials,
    .member-portal,
    .contact-us,
    .subscribe .content {
      display: flex;
      row-gap: 28px;
    }

    .container {
      grid-template-columns: 2fr 2fr 2fr 1fr 2fr;
      padding: 80px 60px;
      column-gap: 30px;
    }

    .socials {
      ul {
        grid-template-columns: auto;
      }
    }

    .subscribe {
      grid-row-start: auto;
      grid-column: 5;
      min-width: 300px;
      position: relative;
      overflow: hidden;
    }

    .copyright {
      .content-wrapper {
        flex-direction: row;
        padding: 16px 60px;

        div:first-of-type {
          margin: 0;
        }
      }
    }
  }

}

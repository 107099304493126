@import '../../utilities/variables.scss';

.dropdown {
  position: relative;
  margin: 0 auto;
  width: 100%;
  background: $white;
  transition: border 300ms ease-out;
}

.dropdown-control,
.dropdown-option {
  font-size: 14px;
  font-family: soleil, 'sans-serif';
}

.dropdown-control {
  position: relative;
  color: $stone-600;
  padding: 10px 16px;
  cursor: pointer;
  height: auto;
  transition: border 300ms ease-out, color 300ms ease-out;
  border: 1px solid $stone-600;

  &.selected {
    color: $midnight-600;
  }

  &:hover {
    border: 1px solid $midnight-300;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    transition: transform 200ms ease-out, border 200ms ease-out;
    width: 2px;
    height: 8px;
    background: $breeze;
  }

  &::before {
    right: 24px;
    transform: rotate(-45deg);
  }

  &::after {
    transition: transform 200ms ease-out, border 200ms ease-out;
    right: 20px;
    transform: rotate(45deg);
  }
}

.dropdown.active {
  .dropdown-control {
    border-bottom: none;

    &::before {
      transform: rotate(-135deg);
    }

    &::after {
      transform: rotate(135deg);
    }
  }

  .dropdown-options {
    visibility: visible;
    height: auto;
    max-height: 180px;
    opacity: 1;
    transition: max-height 200ms ease-out, opacity 200ms ease-out,
      visibility 200ms ease-out;
    overflow: auto;
    z-index: 1;
  }
}

.dropdown-options {
  position: absolute;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  height: 0;
  max-height: 0;
  transition: max-height 200ms ease-out;
  border: 1px solid $stone-600;
  background: $white;
}

.dropdown-option {
  cursor: pointer;
  padding: 10px 18px;
  transition: background 200ms ease-out;

  &:hover {
    color: $midnight;
    background: $stone;
  }
}

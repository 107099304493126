.subscribe-form {

  form {

    .form-content {
      display: flex;
      flex-direction: column;
      margin-top:24px;

      > div {
        display: flex;
        column-gap: 7px;
      }

      .label {
        display: none;
      }
    }
  }

  input {
    flex: 1;
    border-radius: 2px;

    &:focus {
      outline: none;
    }
  }

  button {
    background: $midnight;
    border-radius: 100%;
    flex: 0 0 51px;
    height: 51px;
    margin-top: -2px;
    padding: unset;
    width: 51px;

    path {
      fill: $stone-400;
    }
  }

  .error-msg {
    color: $error;
    flex: 1 1 100%;
    margin-top: 10px;
  }

  .success-msg {
    color: $success;
    flex: 1 1 100%;
    margin-top: 10px;
  }
}

.portal {
    h1,
    .heading.h1 {
        font-family: Soleil;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 100%;
        color: $voyage;

        @media (max-width: 767px) {
            font-size: 38px;
            line-height: 110%;
        }
    }

    h2,
    .heading.h2 {
        font-family: Soleil;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 110%;
        color: $voyage;

        @media (max-width: 767px) {
            font-size: 26px;
            line-height: 110%;
        }
    }

    h3,
    .heading.h3 {
        font-family: Soleil;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 135%;
        color: $voyage;

        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 135%;
        }
    }

    h4,
    .heading.h4 {
        font-family: Soleil;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 135.5%;
        color: $voyage;

        @media (max-width: 767px) {
            font-size: 18px;
            line-height: 135.5%;
        }
    }

    h5,
    .heading.h5 {
        font-family: Soleil;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 135.5%;
        text-transform: uppercase;
        color: $voyage;

        @media (max-width: 767px) {
            font-size: 10px;
            line-height: 150%;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
        &.black {
            color: $midnight;
        }
    }

    // paragraphs
    p,
    p.regular,
    .text-regular {
        font-family: Soleil;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        max-width: 590px;

        @media (max-width: 767px) {
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
        }

        &.bold {
            font-weight: bold;
        }
    }

    p.small,
    .text-small {
        font-family: Soleil;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;

        &.bold {
            font-weight: bold;
        }
    }

    p.meta,
    .text-meta {
        font-family: Soleil;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        text-transform: uppercase;
    }

    // link
    a.link,
    .text-link {
        cursor: pointer;
        color: $voyage-500;
        font-family: Soleil;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 18px;
        text-decoration-line: underline;
        transition: color .25s linear;
        cursor: pointer;

        &:hover {
            color: $voyage-700;
        }
    }

    // lists
    ul,
    ol,
    li {
        font-family: Soleil;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;

        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 150%;
        }
    }

    ul,
    ol {
        padding-left: 20px;
    }

    ol li {
        list-style: auto;
    }

    ul li {
        list-style: disc;
    }

    button,
    .text-button {
        font-family: Soleil;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
    }

    // blockquote
    blockquote {
        font-style: italic;
        font-weight: normal;
        font-size: 20px;
        line-height: 1.5;
        color: $voyage-700;
        padding-left: 24px;
        border-left: 5px solid $highlight;
        margin: 12px 0;
    }

    .pull-quote {
        font-family: Soleil;
        font-style: italic;
        font-weight: normal;
        font-size: 24px;
        line-height: 145%;

        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 145%;
        }
    }
}
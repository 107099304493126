.mini-logo-common {
    width: 42px;
    margin: 0 28px 0 0;
    flex: 0 0 42px;
}

@media screen and (max-width: 767px) {
    .mini-logo-common {
        position: absolute;
        width: 42px;
        left: -21px;
    }
}
@import './utilities/index.scss';
@import './component-styles/components.scss';
@import './page-styles/pages.scss';

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $primary-font;
  background: $white;
  color: $midnight;
  scroll-behavior: smooth;
}

.overflow-hidden {
  @media only screen and (max-width: 1023px) {
    overflow: hidden;
  }
}

button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  display: inline-flex;
  color: $midnight;

  &.standard {
    color: $breeze;

    &:hover {
      color: $voyage-400;
    }
  }
}

figcaption {
  font-size: 12px;
  text-align: center;
}

.content-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

.container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;

  .row {
    margin-left: auto;
    margin-right: auto;
  }

  &.v-pad {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media only screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;

    &.v-pad {
      padding-top: 60px;
    }
  }

  @media only screen and (min-width: 1200px) {
    max-width: 90rem;
  }
}

.row {
  &.no-margin {
    margin-left: 0;
    margin-right: 0;
  }
}

.portal {
  .page-container {
    margin-top: 95px;

    @media only screen and (min-width: 1200px) {
      margin-top: 105px;
    }
  }

  .content-wrapper {
    max-width: 1280px;
  }

  .container {
    @media only screen and (min-width: 1200px) {
      max-width: 80rem;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}

#board-page {
  // Mobile first styles
  background: $white;
  position: relative;
  padding: 0 0 128px;

  .board-section-info {
    padding-left: 15px;
    padding-right: 25px;

    h2 {
      line-height: 40px;
      padding-bottom: 18px;
      display: flex;
      align-items: center;
    }
  }

  .content-wrapper {
    &.two-col {
      display: grid;
      row-gap: 40px;
      padding: 40px 20px 20px 20px;
    }
  }

  .accordion {
    margin: unset;

    .accordion-title {
      button {
        display: flex;
        align-items: center;
        padding: 10px 0 16px 0;

        &:focus {
          outline: none;
        }
      }

      .board-member {
        display: flex;
        align-items: center;
        column-gap: 13px;

        $board-member-dimensions-mobile: 68px;
        img {
          width: $board-member-dimensions-mobile;
          height: $board-member-dimensions-mobile;
          border-radius: 50%;
          align-self: flex-start; // prevent image stretching
        }

        h3 {
          font-family: 'soleil', sans-serif;
          font-size: 18px;
          color: $voyage;
        }

        h5 {
          font-family: 'soleil', sans-serif;
          font-size: 14px;
          color: $midnight-300;
        }

        h6 {
          font-size: 12px;
          font-style: italic;
          color: $midnight-300;
          line-height: 15px;
          font-weight: 400;
          margin: 5px 0 0;
        }
      }
    }

    .accordion-item {
      ul {
        list-style: circle;

        li {
          margin: 0 0 0 20px;
          font-size: 12px;
        }
      }
    }

    .accordion-content {
      display: flex;
      flex-direction: column;
      row-gap: 21px;
      padding-left: 81px;
      padding-right: 25px;
    }
  }

  // Desktop styles
  @media screen and (min-width: $tablet) {
    .board-section-info {
      margin: unset;
      padding: unset;

      h2 {
        margin-bottom: 29px;
      }

      .description {
        padding-left: 70px;
        padding-right: 40px;
      }
    }

    .content-wrapper {
      &.two-col {
        padding: 64px 20px 40px 20px;
      }

      h2 {
        padding-bottom: 30px;
        padding: 0;
      }
    }

    .accordion {
      .accordion-title {
        button {
          padding: 12px 8px 16px 28px;
        }

        .board-member {
          column-gap: 28px;
          $board-member-dimensions-mobile: 125px;

          img {
            width: $board-member-dimensions-mobile;
            height: $board-member-dimensions-mobile;
            border-radius: 50%;
          }

          h3 {
            font-size: 22px;
          }

          h5 {
            font-size: 16px;
          }
        }
      }

      .accordion-content {
        padding-left: 182px;
        padding-bottom: 52px;
      }

      .accordion-item {
        ul {
          li {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media screen and (min-width: $desktop) {
    .content-wrapper {
      &.two-col {
        grid-template-columns: 5fr 7fr;
        column-gap: 30px;
        padding: 64px 60px 160px 60px;
      }
    }
  }
}

.document-page {
    .content-wrapper {
        padding: 50px;
    }
    h3, p, ul {
        margin: 0 0 20px;
    }
    p {

    }
    ul {
        list-style: circle;
        margin: 20px 0 20px 20px;

        li {
            margin: 0 0 0 20px;
        }
    }
    @media screen and (max-width:767px) {
        .content-wrapper {
            padding: 20px;
        }
    }
}
.portal-nav-sub-menu {
  position: absolute;
  top: 54px;
  z-index: 5;
  left: -30px;
  overflow: hidden;
  background: $stone-300;
  width: 20vw;

  .content {
    padding: 20px 32px;
  }

  .control {
    width: 100%;
    text-align: right;
    margin: 0 60px 60px 0;
    z-index: 12;

    svg {
      transition: 0.3s;
    }

    svg {
      &:hover {
        transform: rotate(90deg);
        transition: 0.3s;
      }
    }
  }

  .details {
    ul {
      display: block;
      padding-left: 0;

      li {
        display: block;
        margin-right: 0;
        margin-bottom: 24px;
        border-radius: 2px;

        &:hover {
          display: inline-block;
          background: $stone;
        }

        &:last-child {
          margin-left: 0;
          margin-bottom: 0;
        }

        a {
          display: flex;
          align-items: center;
          color: $midnight;
          text-decoration: none;
          font-size: 14px;
          line-height: 18px;

          &:hover {
            .icon {
              transform: translateX(9px);
              transition: 0.3s;
            }
          }
        }

        .icon {
          margin-left: 15px;
          transition: 0.3s;
          min-width: 14px;
          width: 14px;
        }
      }
    }
  }

  .section {
    flex: 1;
    padding: 0 60px 60px;
    display: flex;
    flex-direction: column;
  }

  h3 {
    color: $voyage;
    margin-bottom: 60px;
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    top: 70px;
    padding: 0;
    margin-top: 0;
    height: calc(100% - 70px);
    overflow: auto;
    z-index: 10;

    .content {
      padding: 0;
      height: 100%;
      overflow-y: scroll;
    }

    h3 {
      font-size: 18px;
      margin-bottom: 54px;
    }

    .control {
      text-align: left;
      margin: 0;
      display: flex;
      align-items: center;
      height: 80px;
      background: $voyage;
      position: fixed;

      h3 {
        margin-bottom: 0;
        color: $stone;
      }
    }

    .details {
      display: block;
    }

    .close {
      width: 100%;
      height: 100%;

      a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 20px;
      }

      .icon {
        width: 21px;
        margin-right: 21px;
      }

      svg {
        margin-top: 9px;
      }
    }

    .section {
      flex: 0 0 100%;

      &:first-of-type {
        padding-top: 134px;
      }
    }

    li {
      .icon {
        svg {
          margin-bottom: 1px;
        }
      }
    }
  }
}

.create-account-form-header {
	margin-bottom: 24px;

	.create-acc-form-step-index {
		h4 {
			color: $voyage;
		}
	}

	.create-acc-form-step-label {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		p {
			font-size: 12px;
			color: $midnight-200;
			text-transform: uppercase;
		}
	}
}

.create-account-form {
	&.step-3 {
		.tnc-permission {
			.tnc-link {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}

	&.form-submitted {
		.btn-fill {
			a {
				line-height: 50px;
				font-weight: 600;
				font-size: 14px;
				font-family: soleil, sans-serif;
			}

			&:hover {
				a {
					color: $white;
				}
			}
		}
	}
}
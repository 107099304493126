.action-card {
    background: $border;
    border-radius: 5px;
    padding: 1px;

    .layout {
        align-items: flex-start;
        background:  $stone-300;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        padding: 16px;
        gap: 16px;

        .content {
            width: 100%;
            display: flex;
            flex-direction: column;

            .wrapper{
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    .title {
        color: $midnight-500;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
    }

    .sub-title{
        color: $midnight-300;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    .value {
        color: $stone-700;
        flex-shrink: 0;
    }

    .link {
        font-size: 14px;
        line-height: 18px;
        text-decoration-line: underline;
        color: $voyage-500;
        font-weight: 400;
        font-style: normal;
        cursor: pointer;
        margin-top: 4px;

        &:hover {
            color: $breeze-500;
        }
    }

}

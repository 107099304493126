.member-welcome {
	background: $white;
	padding-top: 30px;
	padding-bottom: 30px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;

	> .container {
		flex: 1 1 100%;

		@media (max-width: 1023px) {
			max-width: 500px;
		}
	}

	@media (min-width: 1024px) {
		position: fixed;
		height: 100vh;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.logo {
		max-width: 139px;
	}

	.introduction {
		flex: 1 1 100%;
		margin-bottom: 22px;

		@media (min-width: 1024px) {
			max-width: 380px;
			margin-top: 40px;
		}

		h1 {
			margin-bottom: 16px;

			@media (min-width: 1024px) {
				margin-bottom: 32px;
			}
		}

		.small-text {
			font-weight: bold;
			font-size: 20px;
			line-height: 1.35;
		}

		.em {
			display: block;
			color: $voyage;
			font-size: 38px;
			line-height: 1.1;
			font-weight: bold;

			@media (min-width: 1024px) {
				font-size: 50px;
				line-height: 1;
			}
		}

		.introduction-text-wrapper {
			max-width: 500px;
			font-size: 14px;
			line-height: 21px;

			p {
				margin-bottom: 16px;
				font-size: 14px;
				line-height: 21px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.attribution-and-legals {
	color: $stone-700;
	background-color: $white;
	padding-top: 32px;
	padding-bottom: 32px;

	@media (min-width: 1024px) {
		background-color: transparent;

		& > .row {
			max-width: initial;
			// margin: auto -0.5rem;
		}
	}

	.privacy-legals,
	.attributions {
		padding: 0;

		p,
		a {
			font-size: 14px;
		}
	}

	.privacy-legals {
		align-items: flex-end;
		align-content: flex-end;
		align-self: flex-end;
	}

	.privacy-legals-nav {
		a {
			color: $stone-700;
		}

		a:first-child {
			padding-right: 9px;
		}

		a:last-child {
			padding-left: 9px;
		}
	}

	.separator {
		display: inline-block;
		vertical-align: middle;
		width: 4px;
		height: 4px;
		background: $stone-700;
		border-radius: 50%;
	}
}
.static-table-wrapper {
    width: 100%;
    overflow-x: hidden;
}

.static-table {
    width: 100%;
    overflow: auto;
    display: block;

    td, th {
        border: 1px solid $white;
        padding: 8px;
        font-size: 14px;
    }

    td {
        padding-top: 24.5px;
        padding-bottom: 24.5px;
        font-weight:400;
    }

    tr:nth-child(even) {
        background-color: $stone-300;
    }
    tr:hover {
        background-color: $stone-500;
    }

    th {
        text-align: left;
        background-color: $voyage-500;
        color: $white;
    }
}

.media-list {
    .list-wrapper {
        margin-top: 30px;
        
        &:first-of-type {
            margin-top: 0;
        };
    }
    
    .list {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr;
    }

    .month-year {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 12px;

        &:after {
            content:'';
            height: 1px;
            flex: 1;background: $stone-600;
        }
    }

    @media screen and (min-width: $desktop) {
        .list {
            grid-template-columns: 1fr 1fr;
        }
    }
}

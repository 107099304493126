#testimonials {
    min-height: 300px;
    overflow: hidden;
    position: relative;

    .blue-background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 50%;
        background: $blue-background;
    }

    .content {
        padding: 40px 20px 50px;
        width: calc(100% - 20px);
        background: $blue-background;
    }

    .quote-icon {
        background: $breeze-600;
        border-radius: 50px;
        width: 73px;
        height: 73px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    .carousel-conatainer {
        row-gap: 25px;
    }

    .slide-container {
        .hide-slide {
            opacity: 0;
            pointer-events: none;
        }

        .slide {
            padding: 40px 25px 35px 25px;
            background: $voyage-600;
            color: $white;
            transition: .3s;
            flex: 1 0 300px;
            height: 375px;
        }

        .testimonial-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        .card-footer {
            display: flex;
            align-items: center;

            .name {
                font-weight: 700;
            }
        }

        .avatar {
            height: 73px;
            flex: 0 0 73px;
            border-radius: 100px;
            overflow: hidden;
            margin: 0 30px 0 0;
            background-color: $stone-400;

            img {
                object-fit: cover;
                height: 100%;
            }
        }
    }


    .controls-container {

        .btn-active {
            &:hover {
                background-color: $voyage-400;
                border: 1px solid $voyage-400;
            }
        }

        .btn-disabled {
            pointer-events: none;
            border: 0;
            border-radius: 50px;
        }

        .breadcrumbs {
            display: flex;
            gap: 5px;
            margin-top: 30px;
        }

        .active-breadcrumb {
            svg {
                max-width: 56px;
                width: 100%;
            }

            path {
                stroke: $breeze;
                transition: .3s;
            }
        }
    }

    @media screen and (min-width: $tablet) {
        .content {
            padding: 60px 60px 50px;
        }

        .carousel-conatainer {
            row-gap: 25px;
        }

        .controls-container {
            flex: 1 0 390px;
        }

        .slide-container {
            .slide {
                width: 485px;
                padding: 40px;
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .content {
            padding: 108px 60px;
            $gutter: 118px;
            width: calc(100% - #{$gutter});
        }

        .controls {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }
}
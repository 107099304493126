.main-header {
    header {
        z-index: 10;

        &.fixed {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }

        &.opac-white-background {
            background: rgba($stone-300, 0.8);
            box-shadow: 0px 4px 17px rgba($midnight-200, 0.06);
            backdrop-filter: blur(30px);
        }

        a.menu {
            min-width: 29px;
            width: 29px;
        }

        .menu-icon {
            width: 29px;
        }
    }

    .header {
        height: 124px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 60px;
    }

    .logo {
        margin-right: 2vw;
        width: 155px;
        min-width: 155px;
    }

    nav {
        height: 100%;
        z-index: 9;
    }

    .navigation {
        display: flex;
        height: 100%;
        align-items: center;

        li {
            height: 100%;
            display: flex;
            align-items: center;

            &:last-of-type {
                margin-left: 9px;
            }
        }

        .menu-item {
            height: 100%;
            display: flex;
            align-items: center;
            color: $midnight;
            text-decoration: none;
            white-space: nowrap;
            font-size: 14px;
            column-gap: 3px;

            &:hover,
            &.focused {
                background: $voyage;
                color: $stone;

                path {
                    fill: $stone;
                }
            }

            path {
                fill: $breeze;
            }
        }

        a {
            padding: 0 2vw;
            font-weight: 600;

            @media screen and (max-width: 1260px) {
                padding: 0 10px;
            }
        }
    }

    @media screen and (max-width: 1090px) {
        .header {
            height: 70px;
            padding: 0 20px;
        }

        .logo {
            width: 115px;
            min-width: 115px;
        }

        // Put this in a higher point break
        nav {
            position: fixed;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($stone-300, 0.6);
            backdrop-filter: blur(30px);
            z-index: 9;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: rgba($stone-300, 0.9);
                filter: blur(50px);
                z-index: 1;
            }

            ul {
                position: relative;
                z-index: 5;
            }
        }

        .navigation {
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            top: 70px;

            li {
                height: 80px;
                width: 100%;

                &:last-of-type {
                    margin-left: 0px;
                    padding-left: 40px;
                }
            }

            .menu-item {
                font-size: 20px;
                width: 100%;
                font-weight: 700;
                padding: 0 40px;
                transition: 0ms;

                .icon {
                    margin: 6px 0 0 18px;
                }
            }
        }
    }
}
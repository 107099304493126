#feeds-listing-sidebar {
  margin-left: 40px;

  #newsroom-cta-box {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    border: 1px solid #e8e9ea;
    padding: 2rem;
    background-color: #f8f8f8;
  }
}
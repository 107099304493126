.onboarding-form {
	position: relative;
	display: flex;
	flex-direction: column;
	background: $stone-300;
	padding: 0 !important;

	@media (min-width: 1024px) {
		min-height: 100vh;
	}

	.container {
		@media (max-width: 1023px) {
			max-width: 500px;
		}
	}

	.onboarding-form-wrapper {
		margin-bottom: 42px;

		@media (min-width: 1024px) {
			margin: 0 auto 42px;
			max-width: 330px;
			width: 100%;

			.container {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.forgot-password-btn {
		font-size: 14px;
		text-decoration: underline;
	}

	.assist-actions {
		margin: 26px 0;
	}
}
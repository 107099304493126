.no-articles-found {
  h3 {
    color: $voyage;
    margin-bottom: 0.5em;
  }

  .search-tips {
    margin-bottom: 1em;

    ul {
      list-style-type: disc;
      padding-left: 1em;

      li {
        font-weight: 300;
      }
    }
  }

  .quicklinks-section {
    margin-bottom: 1.5em;
    .quick-links {
      .btn-quick-link {
        margin-bottom: 0;
      }
    }
  }
}

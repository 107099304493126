.campaign,
.campaign-list, 
.campaign-tags {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.campaign-tags {
  margin-left: 8px;
  border-left: 1px solid $border;
  padding-left: 24px;
}
#home-page {
  .hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 96px;
    padding-top: 0;
    .content-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
    }

    .hero-text {
      align-self: flex-start;
      flex: 1 0 50%;
      padding: 0 60px 0 0;

      p {
        margin: 30px 0;
      }

      .hero-p p {
        padding: 0 80px 0 0;
      }
    }

    .btn {
      margin: 0 0 30px;
    }

    .hero-image {
      flex: 1 0 50%;
      padding: 0 60px 0 20px;
      width: 100%;
    }

    .image-container {
      margin: 0 auto;
    }

    .down-arrow {
      width: 70px;
      height: 70px;
      background: $midnight;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      $gutter: 124px;
      bottom: -37px;
      left: calc(50% - (70px / 2));
      padding: unset;
      margin: unset;

      .icon {
        height: unset;
        width: unset;
      }

      path {
        fill: $stone;
      }

      &:focus {
        outline: none;
      }
    }
  }

  @media screen and (max-width: $desktop) {
    .hero-section {
      .content-wrapper {
        flex-direction: column;
      }

      .down-arrow {
        display: none;
      }

      .hero-text,
      .hero-image {
        padding: 0 20px;
      }
      .hero-p p {
        padding: 0;
      }

      .image-container {
        margin-top: -124px;
        transform: translateY(172px);
      }

      .btn {
        margin: 0;
      }
    }
  }
}

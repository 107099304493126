.cart {
  .item-listing-headers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: $midnight-300;
    padding-bottom: 4px;
  }

  .cart-list {
    border-top: 1px solid $stone-600; 
  }

  .total {
    align-items: center;
    align-self: flex-end;
    column-gap: 28px;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .total-message {
    color: $midnight-300;	
    text-align: right;
  }

  .total-cost {
    background-color: $stone-400;
    padding: 8px;
  }

  .bottom {
    display: flex;
    margin-top: 64px;
    
  }
}
.article-content-wrapper {
  display: grid;
  padding: 40px 20px 128px 20px;
  column-gap: 30px;
  row-gap: 48px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'articleContent'
    'articleSidebar';

  .breadcrumbs {
    margin-bottom: 40px;

    p {
      text-transform: uppercase;
      font-weight: 600;
      color: $midnight-200;
      font-size: 14px;
    }
  }

  .article-content {
    grid-area: articleContent;

    .content,
    .accordion,
    .article-callout {
      max-width: 515px;
    }

    a {
      &:not(.btn) {
        text-decoration: underline;
        color: $voyage;
      }
    }

    h1 {
      color: $voyage;
      font-size: 18px;
      line-height: 135.5%;
    }

    blockquote {
      font-size: 24px;
      line-height: 36px;
      color: $voyage;
      font-style: italic;
    }

    ol,
    ul {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding-left: 24px;
    }

    ul {
      list-style: disc;
    }

    ol {
      list-style: decimal;
    }

    li {
      padding-left: 16px;

      &::marker {
        color: $voyage;
        margin-right: 15px;
      }
    }

    .stretch-media {
      width: 618px;
      max-width: 618px;
    }

    .article-video {
      @extend .stretch-media;
      margin-top: 30px;
    }

    figure {
      @extend .stretch-media;
    }

    img,
    video {
      height: 100%;
      object-fit: cover;
    }

    // Box with blue left border
    .box {
      border: 1px solid $stone-600;
      border-left: 3px solid $breeze;
    }

    h1 {
      padding: 1rem 0;
    }
  }

  @media screen and (max-width: $tablet) {
    .breadcrumbs {
      display: none;
    }

    .article-content {
      width: 100%;
      order: 0;

      div {
        width: 100%;
        max-width: none;
      }

      blockquote {
        max-width: none;
      }

      figure {
        width: 100%;
        height: auto;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    padding: 64px 20px;

    .article-content {
      .article-video {
        margin-top: 40px;
      }

      h1 {
        font-size: 25px;
        line-height: 135%;
      }

      h1 {
        padding: 1.5rem 0;
      }

      li {
          padding-bottom: 10px;
      }

    }
  }

  @media screen and (min-width: $desktop) {
    padding: 64px 60px 60px;
    grid-template-columns: 2fr 10fr;
    grid-template-areas: 'articleSidebar articleContent';
  }
}

.article-footer {
  position: relative;
  margin-top: 100px;
  margin-bottom: 160px;

  .blue-background {
    background: $blue-background;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 20%;
  }

  .content-wrapper {
    .blue-background {
      right: 30%;
      z-index: 0;
    }
  }

  header {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .btn {
      margin: 0 10% 0 0;
    }
  }

  .heading {
    max-width: 739px;
    display: flex;
    padding: 70px 0;
    z-index: 1;
    align-items: center;
  }

  .articles {
    display: flex;
    @include emulated-gap(20px);
    padding: 0 70px 70px;
  }

  .article-card {
    flex: 1;
  }

  .latest-articles {
    position: relative;

    & + .related-articles {
      margin-top: 100px;
    }
  }

  .related-articles {
    position: relative;
  }

  @media screen and (max-width: 767px) {
    .content-wrapper {
      .blue-background {
        right: 45px;
        z-index: 0;
      }
    }
    header {
      flex-direction: column;
      align-items: flex-start;

      .heading {
        padding: 70px 0 30px;

        h2 {
          align-self: center;
        }
      }
      .btn {
        margin: 0 0 30px;
        display: block;
      }
    }
    .articles {
      padding: 0 20px 70px;
    }
  }
}

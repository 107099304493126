#error-page {
  background: url('../../images/big_logo.png') no-repeat;
  background-position: 105% 320px;

  .content-wrapper {
    padding: 124px 60px 170px;
  }

  h1,
  h4,
  .btn {
    margin-top: 40px;
  }

  h4 {
    color: $voyage;
  }

  @media screen and (max-width: 767px) {
    background-position: 125% 400px;
    background-size: 75%;

    .content-wrapper {
      padding: 70px 20px 170px;
    }
  }
}
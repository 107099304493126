.portal-nav-mobile-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $white;
	opacity: 0;
	transition: opacity 300ms ease-out;
	pointer-events: none;

	&.active {
		opacity: 1;
		pointer-events: auto;
	}
}  
@import '../../utilities/variables.scss';

.radio-button-wrapper {
  margin: 0.5rem;
  position: relative;

  .radio-button {
    margin: 0;
    padding: 0;
    opacity: 0;

    &:checked {
      & + .radio-button-label {
        &::before {
          box-shadow: inset 0 0 0 8px $breeze;
          border-color: $breeze;
        }

        &::after {
          transform: scale(0.8);
          background-color: $breeze;
        }
      }
    }
  }

  .radio-button,
  .radio-button-label::before,
  .radio-button-label::after {
    position: absolute;
    left: 0;
    top: 2px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $stone-600;
  }

  .radio-button-label {
    display: inline-block;
    position: relative;
    padding: 0 0 0 24px;
    vertical-align: top;
    color: $midnight;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 14px;
    pointer-events: none;

    &::before,
    &::after {
      content: '';
      border-radius: 50%;
    }

    &::after {
      box-shadow: inset 0 0 0 3px $white;
      transform: scale(0);
      transition: box-shadow 200ms ease-out, transform 300ms ease-out;
    }

    &::before {
      border: 1px solid $stone-600;
      transition: background 200ms ease-out, box-shadow 200ms ease-out;
    }
  }
}

.form-type-selector {
	position: relative;
	background: $stone;
	padding: 8px;
	margin: 40px auto 32px;
	flex: 0 1 auto;
	border-radius: 3px;

	@media (min-width: 1024px) {
		max-width: 330px;
		margin: 60px auto 32px;
	}

	@media (min-width: 1024px) {
		margin: 60px auto 60px;
	}

	&.btn-signin-acitve {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.btn-bg {
			transform: translate3d(0, 0, 0);
		}
	}

	&.btn-create-acc-active {
		.btn-bg {
			transform: translate3d(100%, 0, 0);
		}
	}

	.btn {
		height: 40px;
		line-height: 1;
		padding: 8px 16px;
		width: 50%;

		p {
			position: relative;
			font-weight: bold;
			font-size: 14px;
			z-index: 1;
			transition: color 200ms ease-out;
		}
	}

	.btn-bg {
		position: absolute;
		width: 48%;
		height: 40px;
		background-color: $white;
		box-shadow: 0px 4px 9px rgb(185 185 186 / 40%);
		border: 1px solid $white;
		border-radius: 2px;
		transition: transform 300ms ease-out;

		@media (min-width: 768px) {
			width: 48.8%;
		}

		@media (min-width: 1024px) {
			width: 157px;
		}
	}

	.btn-signin,
	.btn-create-acc {
		text-transform: uppercase;
		text-align: center;
	}

	.btn-signin-active,
	.btn-create-acc-active {
		p {
			display: block;
			color: $voyage;
		}
	}
}

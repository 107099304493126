@import '../../utilities/variables.scss';

.form {
  margin-bottom: 48px;

  .H2 {
    margin-bottom: 48px;
  }

  .H3 {
    margin-bottom: 16px;
  }
}

.form-title {
  color: $voyage;
  margin-bottom: 24px;

  &.medium {
    font-weight: bold;
    font-size: 25px;
    line-height: 1.35;
  }

  &.large {
    font-weight: bold;
    font-size: 35px;
    line-height: 1.1;
  }
}

.form-header {
  margin-bottom: 48px;

  .H2 {
    margin-bottom: 16px;
  }
}

.form-text {
  font-size: 14px;
}

.legend {
  color: $voyage;
  margin-bottom: 16px;

  &.small {
    font-weight: bold;
    font-size: 20px;
    line-height: 135.5%;
  }
}

.fieldset {
  margin-bottom: 1em;
}

.text-field-error {
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.5;
  color: $error-red;
  margin-top: 5px;
}

.form-hint {
  font-size: 14px;
  line-height: 1.5;
}

.form-group {
  width: 100%;
  margin-bottom: 48px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-width: 590px;

  &:last-child {
    margin-bottom: 0;
  }
}

.form-success {
  display: flex;
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-align: left;

  &.hidden {
    visibility: hidden;
  }
}

.portal-footer-nav-col {
  padding-right: 16px;

  .nav-title {
    color: $voyage;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.35;
  }

  .nav-link {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
    margin-top: 24px;
  }
}

#news-and-resources {
  .caption {
    margin: 0;
  }

  .categories-wrapper {
    padding: 40px 20px;

    ul {
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
      column-gap: 12px;
      margin-top: 16px;
    }

    .show-me {
      font-size: 14px;
    }

    .active {
      background: $stone-600;
      pointer-events: none;
    }
  }

  .article-list {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr;
    margin-top: 40px;
  }

  @media screen and (min-width: $tablet) {
    .article-list {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (min-width: $desktop) {
    .categories-wrapper {
      padding: 70px 60px;
    }

    .article-list {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@import './news-and-resources.scss';
@import './article-banner.scss';
@import './article-content.scss';
@import './article-member.scss';
@import './article-callout.scss';
@import './article-sidebar.scss';
@import './article-footer.scss';

#article-page {
  .find-a-bank {
    position: relative;
    margin: 100px 0 0;

    .blue-background {
      background: $blue-background;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 50%;
    }

    header {
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .blue-background {
        right: 20%;
        z-index: 0;
      }
    }

    .heading {
      max-width: 739px;
      display: flex;
      padding: 70px 0;
      z-index: 1;
      align-items: center;

      h2 {
        // ensure div inside h2 uses h2 styles
        div {
          font-size: inherit;
          font-weight: inherit;
          font-style: inherit;
          line-height: inherit;
        }
      }
    }

    a {
      margin-right: 10%;
      z-index: 1;
      flex: 0 0 195px;
      text-align: center;
    }
  }

  @media screen and (max-width: 767px) {
    .find-a-bank {
      margin: 34px 0 134px;

      header {
        padding: 0 20px 0 35px;
        flex-direction: column;
        position: relative;
        align-items: flex-start;

        .blue-background {
          right: 45px;
        }
      }

      .heading {
        margin-bottom: 0px;
        padding: 50px 0;
      }

      a {
        bottom: -25px;
        position: absolute;
        width: 195px;
      }
    }
  }
}

@import '../utilities/variables.scss';

.btn-group {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

//buttons
.btn {
  height: 54px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  padding: 10px 27px;
  transition: 300ms;
  white-space: nowrap;
  font-family: soleil, sans-serif;

  align-items: center;
  column-gap: 9px;
  display: inline-flex;
  justify-content: center;

  svg {
    transition: 300ms;
  }

  &:hover {
    // Mirco interactions
    svg[class='icon-arrow-thin-long-down'] {
      transform: translateY(4px);
    }

    svg[class='icon-arrow-thin-left'] {
      transform: translateX(-4px);
    }

    svg[class='icon-arrow-thin-right'] {
      transform: translateX(4px);
    }

  }
}

// Button Variants
.btn-primary {
  color: $midnight;
  border: 2px solid $breeze;

  path {
    color: $breeze;
  }

  &:hover {
    background: $voyage-400;
    border-color: $voyage-400;
    color: $stone-300;

    path {
      fill: $stone;
    }
  }
}

.btn-secondary {
  color: $midnight;
  background: $highlight;
  border: 2px solid $highlight;

  path {
    color: $midnight;
  }

  &:hover {
    background: $breeze-300;
    border-color: $breeze-300;

    path {
      fill: $midnight;
    }
  }

  &:focus {
    background: $breeze;
    border-color: $breeze;
  }
}

// Member Portal Variant
.btn-fill,
.btn-outline,
.btn-text {
  display: flex;
  align-items: center;

  height: 45px;

  .icon-left {
    position: relative;
    top: 2px;
  }

  &:disabled {
    color: $stone-700;
    background: $stone-400;
    cursor: unset;
  }
}

.btn-fill {
  background: $highlight;
  border: none;
  justify-content: center;

  svg[class^='icon-'] {
    path {
      fill: $midnight;
    }
  }

  &:hover {
    background: $breeze;
    color: $white;

    svg[class^='icon-'] {
      path {
        fill: $white;
      }
    }
  }

  &.btn-disabled {
    background: $stone-400;
    color: $stone-700;

    svg[class^='icon-'] {
      path {
        fill: $stone-700;
      }
    }
  }
}

.btn-outline {
  background: $white;
  border: 2px solid $breeze;
  justify-content: center;

  &:hover {
    background: $breeze;
    color: $white;

    svg[class^='icon-'] {
      path {
        fill: $white;
      }
    }
  }

  &:disabled {
    border: none;
  }

}

.btn-text {
  background: transparent;
  border: 0;

  &:hover {
    color: $breeze;

    svg[class^='icon-'] {
      path {
        fill: $breeze;
      }
    }
  }

  &.btn-text-underline {
    text-decoration: underline;
  }
}

.btn-fill-disabled,
.btn-outline-disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: $stone-700;
  background: $stone-400;
  border: none;

  svg[class^='icon-'] {
    path {
      fill: $stone-700;
    }
  }
}

.btn-text-disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: $stone-700;

  svg[class^='icon-'] {
    path {
      fill: $stone-700;
    }
  }
}

.btn-full-width {
  width: 100%;
}

// Portal
.portal {
  .btn {
    column-gap: 8px;
    height: 42px;
    padding: 4px 16px;
    
    &.btn-go-back {
      padding: 0;
  
      svg[class^='icon-'] {
        path {
          fill: $midnight-500;
        }
      }
  
      &:hover {
        svg[class^='icon-'] {
          path {
            fill: $breeze;
          }
        }
      }
    }
  }

  .btn-round {
    background-color: $voyage-500;
    height: 45px;
    position: relative;
    transition: background 200ms ease-out;
    width: 45px;
    border-radius: 50%;
    flex-shrink: 0;

    &:hover {
      background-color: $breeze-500;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    svg path {
      fill: $white;
    }
  }

  .btn-round-arrow {
    background: $white;
    border-radius: 50%;
    border: 1px solid $breeze;
    box-sizing: border-box;
    height: 50px;
    transition: background 200ms ease-out, border 200ms ease-out;
    width: 50px;
    flex-shrink: 0;

    &:hover {
      background-color: $stone-300;
      border: none;
    }

    svg path {
      fill: $midnight;
    }
  }
}

.subpage-header {
    background: $blue-background;
    padding-top: 70px;
    position: relative;
    margin-bottom: 30px;

    h1 {
        max-width: 455px;
    }

    .content-wrapper {
        padding: 44px 30px 54px 20px;
    }

    .breadcrumbs {
        @extend .breadcrumbs-base;
    }

    @media screen and (max-width: $tablet) {   
        .breadcrumbs {
            display: none;
        }
    }

    // Desktop styles
    @media screen and (min-width: $tablet) {
        padding-top: 124px;

        .content-wrapper {
            padding: 72px 60px;
        }
        
        h1 {
            max-width: 555px;
        }
    }

    @media screen and (min-width: $desktop) {
        padding-top: 124px;

        .content-wrapper {
            padding: 45px 60px 70px 60px;
        }
        
        h1 {
            max-width: 555px;
        }
    }
}
.article-member {
    @extend .box;
    display: flex;
    flex-direction: row;
    margin: 40px 0 0;
    
    .member-link {
        padding: 25px;
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $stone-300;
    }

    .member-logo {
        width: 45%;
        height: 110px;
        padding: 0 20px;
        background: $white;
        
        img {
            object-fit: contain;
        }
    }
}

@media screen and (max-width: 767px) {
    .article-member {
        flex-direction: column;

        .member-link {
            width: 100%;
        }

        .member-logo {
            width: 100%;
            height: 160px;
        }
    }
}
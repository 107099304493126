.news-listing {
  row-gap: 16px;
  display: flex;
  flex-direction: column;
}
  
.filter-regulators,
.filter-selected-topics {
  .filter-list {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
  }

  .filter-label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    color: $midnight-300;
    padding-bottom: 4px;
  }
}

#feedback-action {
  scroll-margin-top: 100px;
}

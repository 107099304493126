.card-listing {
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  width: fit-content;
  width: 100%;
  max-width: 588px;

  &.full {
    max-width: initial;
    .card {
      max-width: initial;
    }
  }
}

.card-grouping {
  display: flex;
  flex-flow: column nowrap;
  gap: 48px;
}

.card {
  background: linear-gradient(to right, $voyage-300, $blue-background);
  box-shadow: -8px 8px 0px $blue-background;
  padding: 2px;
  width: 100%;
  max-width: 371px;

  &.no-shaddow {
    background: $border-medium;
    box-shadow: none;
    padding: 1px;
    min-height: 0;
  }

  &.small {
    .with-children-container {
      flex-direction: column;
    }
  }

  .columns {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    column-gap: 8px;

    @media screen and (max-width: 550px) {
      flex-direction: column;
    }

    .row {
      width: calc(50% - 4px);
      margin-right: 0;
      margin-left: 0;

      @media screen and (max-width: 550px) {
        width: 100%;
      }
    }
  }
}

.card__header {
  .tagged {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 8px;
    flex-flow: row wrap;
    max-height: 62px;
    overflow: hidden;
  }
}

.card__layout {
  min-width: 120px;
  min-height: 219px;
  background: $white;
  display: flex;
  flex-direction: column;
  padding: 32px;
  row-gap: 16px;
  height: 100%;
}

.card__details {
  .row {
    align-items: center;
    border-bottom: 1px solid $stone-600;
    border-top: 1px solid $stone-600;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: -1px;
    padding: 8px 0;
    color: $midnight-300;

    &:last-of-type {
      border-bottom: 1px solid $stone-600;
    }

    .meta-item {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    &.inline-meta-row {
      column-gap: 64px;
      overflow: hidden;

      .meta-item {
        max-width: 300px;
      }
      .text-meta {
        max-width: 300px;
      }
    }
  }



  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .text-meta {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.card__actions {
  display: flex;
  flex-flow: row wrap;
  column-gap: 32px;
  margin-top: auto;

  .btn-text {
    padding-left: 0;
  }

  .tag {
    margin: unset;
  }
}

.card__content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.carousel .card__details .inline-meta-row {
  column-gap: 32px;
}

.load-more {
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;

  .meta {
    color: $stone-700;
  }
}

@media screen and (min-width: 673px) {
  .card {
    max-width: 588px;
  }
}

.contact-list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: -12px;

    .contact-details {
        // border-top: 1px solid $stone-600;
        display: flex;
        gap: 30px;
        padding: 16px 16px 0 16px;
        width: 100%;

        .icon-outline {
            align-items: center;
            border: 1px solid $stone-600;
            display: flex;
            height: 40px;
            justify-content: center;
            width: 40px;
            min-width: 40px;
            border-radius: 50%;
        }

        .icon {
            path {
                fill: $voyage;
            }
        }

        .details {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        .subtitle {
            text-transform: uppercase;
            color: $breeze;
        }

        .information {
            > p {
                font-weight: 300;
            }
        }
    }
}
.contact-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.contact-form {
    margin-bottom: 0;
    flex: 0 0 43%;
    @media screen and (max-width: 550px) {
        flex: 0 0 100%;
        margin-bottom: -42px;
        margin-top: 32px;
    }
    &__row {
        display: flex;
        gap: 16px;
        .form-field {
            flex: 1;
        }
    }
}
.contact-info {
    flex: 0 0 50%;
    @media screen and (max-width: 550px) {
        flex: 0 0 100%;
        margin-top: 16px;
    }
}

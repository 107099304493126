#customer-stories {
  padding-top: 72px;
  padding-bottom: 68px;
  position: relative;
  background: url('../../../images/half_logo.png') fixed no-repeat;
  background-position: 0 15vh;
  background-size: 181px 361px;
  overflow-x: hidden;

  .background-half-logo {
    position: absolute;
    height: 361px;
    width: 181px;
    left: 0;
    bottom: -510px;
    z-index: 0;
    display: none;
  }

  header {
    padding: 0 30px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    row-gap: 38px;
    padding-bottom: 38px;
  }

  .heading {
    max-width: 739px;
    display: flex;
    align-items: center;
  }

  .slide-container {

    .card-footer {
      display: flex;
      align-items: center;
      margin-top: 30px;
    }

    .avatar {
      width: 73px;
      height: 73px;
      border-radius: 100px;
      overflow: hidden;

      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }

  .controls-container {
    flex: auto;
    z-index: 1;

    .btn-disabled {
      pointer-events: none;
      border: 0;
      border-radius: 50px;
    }

    .active-breadcrumb {
      svg {
        max-width: 56px;
        width: 100%;
      }

      path {
        stroke: $breeze;
        transition: 0.3s;
      }
    }
  }
 
  .card {
		max-width: 588px;
	}

  @media screen and (min-width: $tablet) {
    background-size: 388px 775px;

    header {
      padding: 0 60px;
      flex-direction: row;
      justify-content: space-between;
    }

  }
}
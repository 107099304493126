.portal-header {
  display: block;
  background: $stone-300;
  border-bottom: 1px solid $border;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  position: fixed;

  &.isOpenMobileNav {
    overflow: scroll;
    min-height: 100vh;
  }
  > .container {
    z-index: 4;
    padding-top: 9px;
    padding-bottom: 10px;
    background: rgba($stone-300, 0.8);
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 100%;
  }

  .logo {
    margin-right: 2vw;
    width: 112px;

    @media (min-width: 1023px) {
      width: 135px;
    }

    a {
      display: block;
    }
  }

  .nav {
    ul {
      display: flex;
      height: 100%;
      align-items: center;

      li {
        height: 100%;
        display: flex;
        position: relative;
        align-items: center;
        margin-right: 16px;

        &:last-of-type {
          margin-right: 0;
          margin-left: 9px;
        }
      }

      .icon {
        margin-left: 9px;
        min-width: 14px;
        width: 14px;
      }

      .menu-item {
        height: 100%;
        display: flex;
        align-items: center;
        color: $midnight;
        text-decoration: none;
        white-space: nowrap;
        font-size: 14px;
        border-radius: 2px;

        &:hover,
        &.focused {
          background: $stone;
        }

        path {
          fill: $voyage;
        }
      }

      a {
        padding: 4px 8px;
        font-weight: 600;
      }
    }
  }
}

.my-account {
  &.manage-users {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    align-items: flex-start;

    .user-list {
      align-self: stretch;
    }

    .heading,
    .user {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 16px;
      gap: 16px;
      justify-content: flex-end;
    }

    .heading {
      background-color: $voyage-500;
      color: $white;
      padding: 8px 16px;

      .h5 {
        font-size: 14px;
        font-weight: bold;
        line-height: 135.5%;
        text-transform: uppercase;
      }
    }

    .user {
      &:nth-of-type(even) {
        background-color: $stone-300;
      }
    }

    .name {
      min-width: 220px;
    }

    .email {
      flex-grow: 1;
      min-width: 240px;
    }

    .actions {
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
}

.article-card {
    height: 260px;
    position: relative;
    background-color: $voyage-300;
    transition: .3s;

    a {
        display: block;
        height: 108px;
        padding: 16px;
        text-decoration: none;

        &:hover {
            font-weight: 400;

            .icon {
                transform: translateX(9px);
                transition: .3s;
            }
        }
    }

    &.has-no-img {
        height: 144px;

        &:hover {
            background-color: $voyage-700;
        }
    }

    .card__title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80%;

        background: rgba($voyage-500, 0.7);
        backdrop-filter: blur(30px);
        color: $stone-400;
    }

    .card__image {
        height: 100%;
        width: 100%;

        img {
            object-fit: cover;
            height: 100%;
        }
    }

    .article__link-containter {
        display: flex;
        align-items: flex-end;
        height: 100%;
        column-gap: 16px;
    }

    .article__date {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: $stone-600;
    }

    .article__title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .article__title, .article__title p {
        font-weight: 700;
        font-size: 16px;
        line-height: 135.5%;
        color: $stone-400;
    }
    
    .article__title-containter {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .icon {
        width: 40px;
        transition: .3s;
        margin-left: auto;

        path {
            fill: $stone-400;
        }
    }

    .white {
        background: rgba($stone-300, 0.8);
        backdrop-filter: blur(30px);

        .article__date {
            color: $midnight-300;
        }

        .article__title, .article__title p {
            color: $midnight;
        }

        .icon path {
            fill: $breeze-500;
        }
    }

    @media screen and (min-width: $desktop) {
        &.large {
            height: 401px;
            max-width: 587px;

            &.has-no-img {
                height: 222px;
            }

            .title, .title p {
                font-size: 20px;
            }
    
        } 
    }

}   
.staff-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.staff-card,
.contact-card {
  width: 100%;
  
  .card-wrapper {
    background: $stone-400;
    border-radius: 2px;
    border: 1px solid $stone-600;
    display: flex;
    gap: 16px;
    width: 100%;
  }

  .content {
    width: 100%;
  }

  .details,
  .contact {
    display: flex;
    flex-direction: column;
  }

  .details {
    padding-bottom: 8px;
  }

  .contact {
    border-top: 1px solid $stone-600;
    padding-top: 8px;
    row-gap: 4px;
  }

  h4 {
    color: $voyage;
  }

  h5 {
    color: $midnight-200;
    text-transform: uppercase;
  }

  p,
  a,
  span {
    color: $midnight-200;
    font-size: 12px;
    font-weight: 600;
    line-height: 135.5%;
    text-transform: uppercase;
  }

  // p,
  // a,
  // h5 {
  //   margin: 2px 0;
  // }

  .note {
    background-color: $white;
    margin-top: 6px;
    padding: 4px 12px;
    width: fit-content;
  }

  a {
    align-items: center;
    display: flex;

    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    abbr[title] {
      border-bottom: none !important;
      cursor: inherit !important;
      text-decoration: none !important;
    }
  }

  .icon {
    align-items: center;
    background-color: $white;
    border-radius: 2px;
    display: inline-flex;
    flex-shrink: 0;
    height: 24px;
    justify-content: center;
    margin-right: 8px;
    width: 24px;

    svg {
      width: 75%;
    }
  }
}

.staff-card {
  max-width: 460px;
  .card-wrapper {
    padding: 24px;
  }
}

.contact-card {
  max-width: 309px;
  .card-wrapper {
    padding: 16px 24px;
  }
}
.form.my-account.institution {
  .institution-logo {
    .institution-logo-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 310px;
      height: 170px;
      background: $stone-300;
      margin-bottom: 16px;

      &:hover {
        .btn {
          svg {
            path {
              fill: $white;
            }
          }
        }
      }

      > .icon {
        width: 50%;
        height: 50%;
        opacity: 15%;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .institution-logo-btns {
      display: flex;
    }
  }
}

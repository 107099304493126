.menu-dropdown {
    position: fixed;
    top: 124px;
    z-index: 5;
    left: 0;
    right: 0;
    box-shadow: 0px 4px 17px rgba($midnight-200, 0.6);
    overflow: hidden;
    background: rgba($stone-300, 0.8);
    backdrop-filter: blur(30px);

    .content {
        display: flex;
        flex-wrap: wrap;
        padding: 80px 60px 60px;
        backdrop-filter: blur(20px);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: rgba($stone-300, 0.8);
            filter: blur(50px);
            z-index: 10;
        }
    }

    .control {
        width: 100%;
        text-align: right;
        margin: 0 60px 60px 0;
        z-index: 12;

        svg {
            transition: .3s;
        }

        svg {
            &:hover {
                transform: rotate(90deg);
                transition: .3s;
            }
        }
    }

    .details {
        flex-wrap: wrap;
        flex-basis: 100%;
        display: flex;
        z-index: 11;
    }

    .section {
        flex: 1;
        padding: 0 60px 60px;
        display: flex;
        flex-direction: column;
    }

    h3 {
        color: $voyage;
        margin-bottom: 60px;
    }

    li {
        margin-bottom: 24px;

        &:last-of-type {
            margin-bottom: 0;
        }

        a {
            display: flex;
            align-items: center;
            color: $midnight;
            text-decoration: none;

            &:hover {
                font-weight: 400;

                .icon {
                    transform: translateX(9px);
                    transition: .3s;
                }
            }
        }

        .icon {
            margin-left: 15px;
            transition: .3s;
            min-width: 14px;
            width: 14px;
        }
    }

    @media screen and (max-width: 1090px) {
        position: fixed;
        top: 70px;
        padding: 0;
        margin-top: 0;
        height: calc(100% - 70px);
        overflow: auto;
        z-index: 10;

        .content {
            padding: 0;
            height: 100%;
            overflow-y: scroll;
        }

        h3 {
            font-size: 18px;
            margin-bottom: 54px;
        }

        .control {
            text-align: left;
            margin: 0;
            display: flex;
            align-items: center;
            height: 80px;
            background: $voyage;
            position: fixed;

            h3 {
                margin-bottom: 0;
                color: $stone;
            }
        }

        .details {
            display: block;
        }

        .close {
            width: 100%;
            height: 100%;

            a {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 20px;
            }

            .icon {
                width: 21px;
                margin-right: 21px;
            }

            svg {
                margin-top: 9px;
            }
        }

        .section {
            flex: 0 0 100%;

            &:first-of-type {
                padding-top: 134px;
            }
        }

        li {
            .icon {
                svg {
                    margin-bottom: 1px;
                }
            }
        }
    }
}
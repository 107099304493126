.footer {
  margin-top: 24px;
  border-top: 1px solid $border;

  &.portal-footer {
    position: relative;
    background: $stone-300;

    > .content-wrapper {
      padding-top: 65px;
      padding-bottom: 147px;
    }

    .content-wrapper {
      padding-left: 28px;
      padding-right: 28px;

      @media only screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      > .row {
        @media (max-width: 1023px) {
          max-width: 350px;
        }

        @media (max-width: 767px) {
          max-width: 316px;
        }
      }
    }

    .navbar-nav {
      right: 0;
    }

    .preferences {
      padding: 0;
      border: none;
    }
  }

  h4.title {
    color: $voyage;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.35;
  }

  .btn {
    max-width: 270px;

    @media (max-width: 767px) {
      max-width: 230px;
    }
  }
}

#sector-news {
    padding-top: 72px;
    padding-bottom: 41px;
    position: relative;
    overflow-x: hidden;

    .background-half-logo {
        position: absolute;
        height: 775px;
        width: 388px;
        left: 0;
        bottom: -510px;
        z-index: 0;
        display: none;
    }

    header {
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 38px;
        padding-bottom: 38px;
    }

    .heading {
        max-width: 739px;
        display: flex;
    }

    .carousel {
        display: flex;
        flex-direction: column-reverse;
    }

    .slide-container {
        display: flex;
        transition: 0.3s;
        z-index: 0;

        .hide-slide {
            opacity: 0;
            pointer-events: none;
        }

        .slide {
            transition: .3s;
            flex: 1 0 587px;
        }

        .card-footer {
            display: flex;
            align-items: center;
            margin-top: 30px;
        }

        .avatar {
            width: 73px;
            height: 73px;
            border-radius: 100px;
            overflow: hidden;

            img {
                object-fit: cover;
                height: 100%;
            }
        }
    }

    .controls-container {

        .btn-disabled {
            pointer-events: none;
            border: 0;
            border-radius: 50px;
        }

        .active-breadcrumb {
            svg {
                max-width: 56px;
                width: 100%;
            }

            path {
                stroke: $breeze;
                transition: .3s;
            }
        }
    }


    @media screen and (min-width: $tablet) {
        header {
            padding: 0 60px;
            flex-direction: row;
            justify-content: space-between;
        }
    }

}
.form-reset-password {
	padding-top: 162px;
	@media screen and (max-width: 1023px) {
		padding-top: 32px;
	}

	h4,
	p.update {
		margin-bottom: 24px;
	}

	button.btn {
		width: 100%;
		max-width: 465px;
	}
}

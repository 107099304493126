.cart-item {
  display: flex;
  padding: 24px 0;
  margin-top: -1px;
  border-top: 1px solid $border-medium;
  border-bottom: 1px solid $border-medium;
  column-gap: 8px;
  align-items: center;

  .title__container {
    flex-grow: 1;
  }

  .link.action {
    flex-shrink: 0;
    width: 5em;
    color: $midnight-300;
  }

  .price {
    flex-shrink: 0;
    text-align: end;
    width: 5em;
  }
}

.training-download {
  @import '../portal/common/title.scss';
  @import '../portal/card/action-card.scss';

  .card-collection {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  p.small,
  .text-small {
    font-family: Soleil;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;

    &.bold {
        font-weight: bold;
    }
  }
}
.no-pad {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.no-spacing {
  margin: 0;
  padding: 0;
}
